import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import Header       from '../header/Header';
import Footer       from '../footer/Footer';
import Home         from '../home/Home';
import Restful      from '../api/restful/Restful';
//import Api          from '../api/Api';
import Activity     from '../activity/Activity';
import Billing      from '../billing/Billing';
import Login        from '../login/Login';
import Admin        from '../admin/Admin';
//import SignUp        from '../sign-up/SignUp';
import Endpoints    from '../../helpers/Endpoints';
import Utilities    from '../../helpers/Utilities';
import './App.scss';

class App extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this.state = {
      activeMainTab: '',
      isLoggedIn: false
    }
  }
  
  // #endregion


  // #region Lifecycle

  /// renders header, home, restful, activity, billing, account, login, footer
  render = () => {
    return (
      <div id="app">
        <Router>
          <lib-header></lib-header>
          <Header
            activeMainTab=        { this.state.activeMainTab }
            isLoggedIn=           { this.state.isLoggedIn }
            onClickLoginButton=   { this.onClickLoginButton }
          />
          <div className="routes">
            <Route
              path="/"
              exact
              render={ () => <Home
                isLoggedIn=         { this.state.isLoggedIn }
                setActiveMainTab=   { this.setActiveMainTab }/>
              }
            />
            <Route
              path="/api/restful"
              render={ () => <Restful
                isLoggedIn=         { this.state.isLoggedIn }
                setActiveMainTab=   { this.setActiveMainTab }/>
              }
            />
            <Route
              path="/activity"
              render={ () => <Activity
                isLoggedIn=         { this.state.isLoggedIn }
                setActiveMainTab=   { this.setActiveMainTab }/>
              }
            />
            <Route
              path="/billing"
              render={ () => <Billing
                isLoggedIn=         { this.state.isLoggedIn }
                setActiveMainTab=   { this.setActiveMainTab }/>
              }
            />
            <Route
              path="/login"
              exact
              render={ () => <Login redirectPath={ '/' } /> }
            />
            <Route
              path="/admin"
              render={ () => <Admin isLoggedIn={ this.state.isLoggedIn }/> }
            />
            {/* <Route
              path="/sign-up"
              render={ () => <SignUp isLoggedIn={ this.state.isLoggedIn }/> }
            /> */}
          </div>
        </Router>
        <Footer />      
      </div>
    );
  }

  ///
  componentDidMount = () => {
    //this.initLoginStatus();
    //var token = this.getToken();
    var token = Utilities.getToken();
    if(token)
      this.setState({ isLoggedIn: true });
  }
  
  // #endregion


  // #region User-Generated Events

  /// sets the current-tab variable
  setActiveMainTab = tabName => {
    this.setState({ activeMainTab: tabName });
  };

  /// log out and check for success
  onClickLoginButton = () => {
    if(this.state.isLoggedIn) {
      Endpoints.logout()
        .then( () => {
          Endpoints.loginStatus()
            .then( loginStatus => {
              if( loginStatus === false )
                this.setState({ isLoggedIn: false });
              else
                window.alert( 'Logout failed, please try again.' );
            } )
        } ).catch( console.log );
    }
  }
  
  // #endregion


  // #region Logic

  ///
  initLoginStatus = async () => {
    var isValidAuth = await Utilities.isValidAuthorization()
      Promise.all([ isValidAuth ])
        .then( () => {
          if( localStorage !== undefined
            && localStorage.getItem( 'Authorization' ) !== undefined
            && localStorage.getItem( 'Authorization' ) !== 'undefined'
            && isValidAuth === true ) {
              this.setState({ isLoggedIn: true });
          }
        } ).catch( console.log );
  }

  // getToken = () => {
  //   let token = document.cookie.split('; ').find(row => row.startsWith('PS_U_TOKEN='));
  //   token = token ? token.split('PS_U_TOKEN=')[1] : undefined;
  //   return token;
  // }

  // #endregion 
}

export default App;