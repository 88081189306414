import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { decodeToken } from 'react-jwt';

import Endpoints from '../../../helpers/Endpoints';
import Utilities from '../../../helpers/Utilities';
import ApiLogTable from '../../layout/api-log-table/ApiLogTable';
import ApiLogList from '../../layout/api-log-list/ApiLogList';
import Pager from '../../layout/pager/Pager';
import './History.scss';
import UserImpersonationInfo from '../../layout/user-impersonation-info/UserImpersonationInfo';
import { ErrorMessages } from '../../../helpers/Config.js';

class History extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this._isMounted = false;
    this.pageInputRef = createRef();
    this.pageSizeInputRef = createRef();
    this.token = decodeToken( Utilities.getToken() );
    this.adminReferralInfo = JSON.parse( Utilities.getSessionStorageKey( "adminReferralInfo" ) );

    this.state = {
      pagerAlertIsOn: false,
      screenWidth: 0,

      pageNumber: !sessionStorage || !sessionStorage.getItem( 'pageNumber_history' ) || sessionStorage.getItem( 'pageNumber_history' ) === 'undefined' ?
        0 : +sessionStorage.getItem( 'pageNumber_history' ),
      pageSize: !sessionStorage || !sessionStorage.getItem( 'pageSize_history' ) || sessionStorage.getItem( 'pageSize_history' ) === 'undefined' ?
        10 : +sessionStorage.getItem( 'pageSize_history' ),
      totalPages: Number.MAX_SAFE_INTEGER,
      pageInput: 0,
      sizeInput: 10,

      isLoading: {
        apiLogs: false
      },
      errorMessage: {
        apiLogs: ''
      },
      
      apiLogs: []
    };
  }
  
  // #endregion


  // #region Lifecycle

  render = () => {
    if( this.props.isLoggedIn === false )
      return <div id="history">Please log in.</div>;
      //Utilities.changeWindowLocation( '/login' );
    return (
      <div id="history">
        { this.adminReferralInfo !== undefined &&
          <UserImpersonationInfo adminReferralInfo={this.adminReferralInfo}></UserImpersonationInfo>
        }
        { this.state.errorMessage.apiLogs !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.apiLogs}</Alert> }
        <h3>History</h3>
        { this.state.screenWidth >= 901 ?
          <ApiLogTable pageName={ 'history' } data={ this.state.apiLogs } isLoading={this.state.isLoading.apiLogs} /> :
          <ApiLogList pageName={ 'history' } data={ this.state.apiLogs } isLoading={this.state.isLoading.apiLogs} />
        }
        <br />
        <Pager
          pageNumber=       { this.state.pageNumber }
          pageSize=         { this.state.pageSize }
          totalPages=       { this.state.totalPages }
          pageInputRef=     { this.pageInputRef }
          pageSizeInputRef= { this.pageSizeInputRef }
          onSubmitPager=    { this.onSubmitPager }
          onKeyPressInput=  { this.onKeyPressPagerInput }
          changeState=      { this.getCustomerData }
        />
        <br />
        <Alert
          style={{ opacity: this.state.pagerAlertIsOn ? 1 : 0 }}
          color="primary"
          isOpen={ true }
          toggle={ () => this.setState({ pagerAlertIsOn: false }) }
          >Oops! Please enter a valid integer and try again.
        </Alert>
        <br />
      </div>
    );
  }

  ///
  componentDidUpdate = ( prevProps, prevState ) => {
    if( this.shouldRerenderData( prevProps, prevState ) ) {
      this.getCustomerData( this.state.pageNumber, this.state.pageSize ).catch( console.log );
    }
  }

  ///
  componentDidMount = () => {
    this._isMounted = true;
    this.props.setActiveMainTab( 'Activity' );
    this.props.setActiveActivityTab( 'History' );
    this.updateWindowDimensions();
    window.addEventListener( 'resize', this.updateWindowDimensions );
    window.addEventListener( 'beforeunload', this.onUnload );
    this.getCustomerData( this.state.pageNumber, this.state.pageSize ).catch( console.log );
  }

  ///
  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener( 'resize', this.updateWindowDimensions );
    window.removeEventListener( 'beforeunload', this.onUnload );
  }
  
  // #endregion


  // #region User-Generated Events

  ///
  closeAlert = () => {
    this.setState({ pagerAlertIsOn: false });
  }

  ///
  onKeyPressPagerInput = event => {
    if( event.key === 'Enter' )
      this.onSubmitPager();
    else if( isNaN( event.target.value ) === false || event.target.value.length === 0 ) {
      event.target.id === 'page-input' ?
        this.setState({ pageInput: event.target.value }) :
        this.setState({ sizeInput: event.target.value });
    }
  }

  ///
  onSubmitPager = () => {
    // if invalid input, turn on alert
    if( this.state.pageInput.length === 0 ||
        this.state.pageInput < 0 ||
        this.state.pageInput > this.state.totalPages - 1 ||
        this.state.sizeInput.length === 0 ||
        this.state.sizeInput < 1 )
    {
      this.setState({ pagerAlertIsOn: true });
      setTimeout( () => { this.setState({ pagerAlertIsOn: false }) }, 3000 ); // turns off alert automatically
    }
    else
      this.getCustomerData( +this.state.pageInput, +this.state.sizeInput, false ).catch( console.log );
  }

  ///
  onUnload = event => {
    event.preventDefault();
    Utilities.setSessionStorageItem( 'pageNumber_history', this.state.pageNumber );
    Utilities.setSessionStorageItem( 'pageSize_history', this.state.pageSize );
  }

  // #endregion


  // #region Api

  /// wrapper for async api calls. does state changes all at once
  getCustomerData = async ( page, pageSize ) => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var errorMessage = { apiLogs: '' };
    var isLoading = { apiLogs: true };

    if( this.props.isLoggedIn ) {
      this.setState({ isLoading: isLoading }, 
        async () => {
          var apiLogs = await Endpoints.getAllApiLogs( this.props.startDate, this.props.endDate, customerId, page, pageSize )
            .catch( exception => {
              console.log(exception);
              errorMessage = `API History: ${ErrorMessages.generic}`;
            } );

          Promise.all([ apiLogs ])
            .then( () => {
              if( this._isMounted ) {
                isLoading.apiLogs = false;

                this.setState({
                  pageNumber: page,
                  pageInput: page,
                  pageSize,
                  sizeInput: pageSize,
                  totalPages: apiLogs.response.totalPages,
                  apiLogs: apiLogs.response.logs,
                  isLoading: isLoading,
                  errorMessage: errorMessage
                });
              }
            } );
        }
      )
    }
  }

  // #endregion


  // #region Helpers

  ///
  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  }
  
  ///
  shouldRerenderData = ( prevProps, prevState ) => {
    return (
      this.props.startDate  !== prevProps.startDate ||
      this.props.endDate    !== prevProps.endDate ||
      this.props.plotBy     !== prevProps.plotBy ||
      this.props.isLoggedIn !== prevProps.isLoggedIn ||
      this.state.pageNumber !== prevState.pageNumber ||
      this.state.pageSize   !== prevState.pageSize
    );
  }
  
  // #endregion
}

History.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  plotBy: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  setActiveMainTab: PropTypes.func.isRequired,
  setActiveActivityTab: PropTypes.func.isRequired
}

export default History;