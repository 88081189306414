import React from 'react';
import PropTypes from 'prop-types';

import './ApiLogTable.scss';

import Utilities from '../../../helpers/Utilities';
import { UrlDomains } from '../../../helpers/Config';
import { Columns } from '../../../helpers/Config';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

// #region Wrapper

///
const ApiLogTable = props => {
  if( props.pageName === 'summary' )
    return renderSummaryTable( props );
  else if( props.pageName === 'details' )
    return renderDetailsTable( props );
  else if( props.pageName === 'history' )
    return renderHistoryTable( props );
  else {
    console.log( 'invalid pageName prop to ApiLogTable' );
    return <div></div>;
  }
}

ApiLogTable.propsTypes = {
  isLoading: PropTypes.bool.isRequired,
  pageName: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default ApiLogTable;

// #endregion


// #region JSX

///
const renderTableHeadings = props => {
  return (
    <thead>
      <tr>
        { props.columns.map( column =>
          <th key={ column.name + column.width } style={{ width: column.width }}>{ column.name }</th> ) }
      </tr>
    </thead>
  );
}

///
const renderSummaryTable = props => {
  return (
    <div id="summary-table-container">
      <table className="summary-table">
        { renderTableHeadings({ columns: Columns.summary }) }
        <tbody>
          { props.data.map( summary =>
            <tr  key={ summary.endpoint }>
              <td onClick={ () => onClickTableRow( summary.endpoint ) }>{ summary.endpoint }</td>
              <td onClick={ () => onClickTableRow( summary.endpoint ) }>{ summary.totalCalls }</td>
              <td onClick={ () => onClickTableRow( summary.endpoint ) }>{ summary.averageTime ? summary.averageTime + 'ms' : 'n/a' }</td>
              <td onClick={ () => onClickTableRow( summary.endpoint ) }>{ Utilities.parseDate( summary.mostRecent ) }</td>
              <td onClick={ () => onClickTableRow( summary.endpoint ) }>{ Utilities.parseAndFormatTime( summary.mostRecent ) }</td>
            </tr>
          ) }
        </tbody>
      </table>
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

///
const renderDetailsTable = props => {
  return (
    <div id="details-table-container">
      <table className="details-table">
        { renderTableHeadings({ columns: Columns.details }) }
        <tbody>
          { props.data.map( log =>
            <tr key={ log.id }>
              <td>{ log.pathAndQuery }</td>
              <td>{ log.elapsedTime ? log.elapsedTime + 'ms' : 'n/a' }</td>
              <td>{ Utilities.parseDate( log.callTime ) }</td>
              <td>{ Utilities.parseAndFormatTime( log.callTime ) }</td>
            </tr>
          ) }
        </tbody>
      </table>
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

///
const renderHistoryTable = props => {
  return (
    <div id="history-table-container">
      <table className="history-table">
        { renderTableHeadings({ columns: Columns.history }) }
        <tbody>
          { props.data.map( log =>
            <tr key={ log.id }>
              <td onClick={ () => onClickTableRow( log.endpoint ) }>{ log.endpoint }</td>
              <td onClick={ () => onClickTableRow( log.endpoint ) }>{ log.pathAndQuery }</td>
              <td onClick={ () => onClickTableRow( log.endpoint ) }>{ log.elapsedTime ? log.elapsedTime + 'ms' : 'n/a' }</td>
              <td onClick={ () => onClickTableRow( log.endpoint ) }>{ Utilities.parseDate( log.callTime ) }</td>
              <td onClick={ () => onClickTableRow( log.endpoint ) }>{ Utilities.parseAndFormatTime( log.callTime ) }</td>
            </tr>
          ) }
        </tbody>
      </table>
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

// #endregion


// #region Logic

///
const onClickTableRow = endpoint => {
  Utilities.setSessionStorageItem( 'endpoint', endpoint );
  window.location = UrlDomains.siteDomain + '/activity/details/';
}

// #endregion