import React from 'react';
import PropTypes from 'prop-types';
import './MonthYearDatePicker.scss';

// #region MonthYearDatePicker Wrapper

///
const MonthYearDatePicker = props => {
  return (
    <div className="month-year-date-picker">
      <YearSelect
        year=           { props.year }
        years=          { props.years }
        onChangeYear=   { props.onChangeYear }
      />
      <MonthSelect
        month=          { props.month }
        months=         { props.months }
        onChangeMonth=  { props.onChangeMonth }
      />
    </div>
  );
}

MonthYearDatePicker.propTypes = {
  month:              PropTypes.object.isRequired,
  months:             PropTypes.array.isRequired,
  onChangeMonth:      PropTypes.func.isRequired,
  year:               PropTypes.object.isRequired,
  years:              PropTypes.array.isRequired,
  onChangeYear:       PropTypes.func.isRequired
}

export default MonthYearDatePicker;

// #endregion


// #region JSX

///
const MonthSelect = props => {
  return (
    <div className="month-select-container">
      <select className="month-select" name="month-select" id="month-select" onChange={props.onChangeMonth} value={props.month.id}>
        {props.months.map((month) => (
          <option key={month.id} value={month.id}>{month.name}</option>
        ))}
      </select>
    </div>
  );
}

MonthSelect.propTypes = {
  month:          PropTypes.object.isRequired,
  months:         PropTypes.array.isRequired,
  onChangeMonth:  PropTypes.func.isRequired
}

///
const YearSelect = props => {
  return (
    <div id="year-select-container">
      <select className="year-select" name="year-select" id="year-select" onChange={props.onChangeYear} value={props.year.id}>
        {props.years.map((year) => (
          <option key={year.id} value={year.id}>{year.number}</option>
        ))}
      </select>
    </div>
  );
}

YearSelect.propTypes = {
  year:           PropTypes.object.isRequired,
  years:          PropTypes.array.isRequired,
  onChangeYear:   PropTypes.func.isRequired
}

// #endregion
