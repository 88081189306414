import React from 'react';
import PropTypes from 'prop-types';

import './AdminTable.scss';

import { Columns } from '../../../helpers/Config';
import Utilities from '../../../helpers/Utilities';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

// #region Wrapper

///
const AdminTable = props => {
  return(
    <div id="admin-table">
      { renderAdminTable( props.customerData ) }
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

AdminTable.propsTypes = {
  isLoading:      PropTypes.bool.isRequired,
  customerData:   PropTypes.array.isRequired
}

export default AdminTable;

// #endregion


// #region JSX

///
const renderTableHeadings = columns => {
  return(
    <thead>
      <tr>
        { columns.map( ( column, index ) =>
          <th
            key={ index }
            style={{ width: column.width,
            textAlign: column.alignment }}
            >{ column.name }
          </th>
        ) }
      </tr>
    </thead>
  );
}

///
const renderAdminTable = customerData => {
  return(
    <table className='admin-table'>
      { renderTableHeadings( Columns.admin ) }
      <tbody>
        { customerData.map( ( customer, index ) =>
          <tr className="line-item" key={ index }>
            <td onClick={ () => onClickCustomer( customer.id, customer.firstName + ' ' + customer.lastName, customer.email ) }>{ customer.firstName + ' ' + customer.lastName }</td>
            <td onClick={ () => onClickCustomer( customer.id, customer.firstName + ' ' + customer.lastName, customer.email ) }>{ customer.email }</td>
            <td onClick={ () => onClickCustomer( customer.id, customer.firstName + ' ' + customer.lastName, customer.email ) } className="right-align">{ customer.id }</td>
            <td onClick={ () => onClickCustomer( customer.id, customer.firstName + ' ' + customer.lastName, customer.email ) } className="right-align">{ customer.callsSinceStartofPayPeriod }</td>
            <td onClick={ () => onClickCustomer( customer.id, customer.firstName + ' ' + customer.lastName, customer.email ) } className="right-align">{ customer.callsLast30Days }</td>
            <td onClick={ () => onClickCustomer( customer.id, customer.firstName + ' ' + customer.lastName, customer.email ) } className="right-align">{ customer.totalCalls }</td>
          </tr>
        ) }
      </tbody>
    </table>
  );
}

// #endregion


// #region Logic

// #endregion


// #region User-Generated Events

const onClickCustomer = ( customerId, fullName, email ) => {
  var adminReferralInfo = {
    customerId: customerId,
    fullName: fullName,
    email: email
  };

  Utilities.setSessionStorageItem( "adminReferralInfo", JSON.stringify( adminReferralInfo ) );
  Utilities.changeWindowLocation( "/billing" );
}

// #endregion