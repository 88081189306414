import React from 'react';
import PropTypes from 'prop-types';

import './LoadingSpinner.scss';

// #region Wrapper

///
const LoadingSpinner = props => {
  return(
    <div id="loading-spinner-container" className={generateClassNames( props )}>
      <div className="loading-spinner">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 38 38" stroke="#26bff1">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(102.306 18 18)">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur=".8s" repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

// #endregion


// #region JSX

// #endregion


// #region Logic

const generateClassNames = props => {
  var classNames = '';

  if( props.isLoading === false || props.isLoading === undefined || props.isLoading === null )
    classNames += ' display-none';

  if( props.dimBackground === true )
    classNames += ' dim-background';

    return classNames;
}

// #endregion


// #region User-Generated Events

// #endregion

LoadingSpinner.propsTypes = {
  isLoading:        PropTypes.bool.isRequired,
  dimBackground:    PropTypes.bool.isRequired
}

export default LoadingSpinner;