import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';
import DatePicker from 'react-date-picker';

import './ActivityHeader.scss';

const ActivityHeader = props => {
  //const [plotByIsOpen, setPlotByIsOpen] = React.useState( false );

  return (
    <div id="activity-header">
      <div className="header-subcontainer">
        <a
          href="/activity"
          className="header-title"
          title="https://developers.pollstar.com"
          >Activity
        </a>
        <div className="spacer"></div>
        <DatePicker
          onChange={ date => onChangeDate( date, 'startDate', props.startDate, props.endDate, props.plotBy, props.setSearchParams ) }
          value={ getPickerDate( 'startDate', props.startDate, props.endDate ) }
        />
        <div className="spacer"></div>
        <DatePicker
          onChange={ date => onChangeDate( date, 'endDate', props.startDate, props.endDate, props.plotBy, props.setSearchParams ) }
          value={ getPickerDate( 'endDate', props.startDate, props.endDate ) }
        />
        {/*<div className="spacer"></div>
        <div className="plot-by-picker">
          <ButtonDropdown isOpen={ state.plotByIsOpen } toggle={ toggleDropdown }>
            <DropdownToggle caret>Plot by: { props.plotBy }</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={ plotByItemOnClick }
                className={ setPlotByItemClassNames( 'Hour' ) }
                >Hour
              </DropdownItem>
              <DropdownItem
                onClick={ plotByItemOnClick }
                className={ setPlotByItemClassNames( 'Day' ) }
                >Day
              </DropdownItem>
              <DropdownItem
                onClick={ plotByItemOnClick }
                className={ setPlotByItemClassNames( 'Week' ) }
                >Week
              </DropdownItem>
              <DropdownItem
                onClick={ plotByItemOnClick }
                className={ setPlotByItemClassNames( 'Month' ) }
                >Month
              </DropdownItem>
              <DropdownItem
                onClick={ plotByItemOnClick }
                className={ setPlotByItemClassNames( 'Year' ) }
                >Year
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>*/}
      </div>
      <div className="header-subcontainer">
        <ButtonGroup className= "header-tabs" size="lg">
          <Button
            href="/activity/summary"
            className={ setActivityTabClassNames( 'Summary', props.activeActivityTab ) }
            title="Summary">
            <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M3,4H7V8H3V4M9,5V7H21V5H9M3,10H7V14H3V10M9,11V13H21V11H9M3,16H7V20H3V16M9,17V19H21V17H9" /></svg>
          </Button>
          <Button
            href="/activity/details"
            className={ setActivityTabClassNames( 'Details', props.activeActivityTab ) }
            title="Details">
            <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M2 14H8V20H2M16 8H10V10H16M2 10H8V4H2M10 4V6H22V4M10 20H16V18H10M10 16H22V14H10" /></svg>
          </Button>
          <Button
            href="/activity/history"
            className={ setActivityTabClassNames( 'History', props.activeActivityTab ) }
            title="History">
            <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" /></svg>
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

ActivityHeader.propTypes = {
  activeActivityTab:  PropTypes.string.isRequired,
  setSearchParams:    PropTypes.func.isRequired,
  startDate:          PropTypes.string.isRequired,
  endDate:            PropTypes.string.isRequired,
  plotBy:             PropTypes.string.isRequired
}

export default ActivityHeader;

// #region User-Generated Events

/*///
const toggleDropdown = () => {
  setState({ plotByIsOpen: !state.plotByIsOpen });
}

///
const plotByItemOnClick = event => {
  sessionStorage.setItem( 'plotBy', event.currentTarget.innerHTML );
  props.setSearchParams( props.startDate, props.endDate, event.currentTarget.innerHTML );
}*/

///
const onChangeDate = ( date, dateType, startDate, endDate, plotBy, setSearchParams ) => {
  var dateString =
    date.getFullYear() + '-' +
    ( date.getMonth() + 1 ) + '-' +
    date.getDate();

  if( dateType === 'startDate' )
    setSearchParams( dateString, endDate, plotBy );
  else if( dateType === 'endDate' )
    setSearchParams( startDate, dateString, plotBy );
}

// #endregion


// #region Helpers

///
const setActivityTabClassNames = ( pageName, activeActivityTab ) => {
  return activeActivityTab === pageName ? "activity-tab active-tab" : "activity-tab";
}

/*///
const setPlotByItemClassNames = ( plotBy, propsPlotBy ) => {
  return propsPlotBy === plotBy ? "active-metric" : "";
}*/

///
const getPickerDate = ( dateType, propsStartDate, propsEndDate ) => {
  var startDate = new Date( propsStartDate );
  var endDate = new Date( propsEndDate );
  
  if( dateType === 'startDate' )
    return startDate;
  if( dateType === 'endDate' )
    return endDate;
  else
    return;
}