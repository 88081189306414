import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../loading-spinner/LoadingSpinner';

///
const EndpointModal = props => {
  return (
    <div id="endpoint-modal" style={{ display: props.isOpen ? "block" : "none" }}>
      <div className="modal-content">
        <div className="header">
          <br />
          <h3 className="title">Endpoints</h3>
          <span className="close" onClick={ props.closeModal }>&times;</span>
        </div>
        <hr />
        <div className="body">
          <ul className="endpoints">
            { props.apiRoles === undefined ?
              console.log( 'apiRoles is undefined' ) :
              props.apiRoles.map( apiRole =>
                <li
                  className={ "endpoint " + setActiveItem( props.endpoint, apiRole.name ) }
                  onClick={ props.onClickItem }
                  key={ apiRole.id }
                  ><div>&nbsp;&nbsp;{ apiRole.name }</div>
                </li> )
            }
          </ul>
        </div>
        <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
      </div>
    </div>
  );
}

EndpointModal.propTypes = {
  isLoading:    PropTypes.bool.isRequired,
  isOpen:       PropTypes.bool.isRequired,
  endpoint:     PropTypes.string.isRequired,
  apiRoles:     PropTypes.array.isRequired,
  closeModal:   PropTypes.func.isRequired,
  onClickItem:  PropTypes.func.isRequired
}

export default EndpointModal;

///
const setActiveItem = ( activeEndpoint, thisEndpoint ) => {
  return activeEndpoint === thisEndpoint ? "active" : "";
}