import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

import './ApiLogChart.scss'

///
const ApiLogChart = props => {
  return (
    <div id="api-log-chart-container">
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
      <Bar
        id="top10-bar"
        data={ props.chartData }
        options={{
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Endpoint Usage",
            fontSize: 28,
            fontFamily: "'Rubik', sans-serif",
            fontColor: "#3b5066"
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontStyle: "'Rubik', sans-serif",
              fontColor: "#3b5066",
              fontSize: 24
          }},
          scales: {
            xAxes: [{
              gridLines: {
                display: false
            }}],
            yAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                precision: 0
          }}]},
          tooltips: {
            enabled: false
          }}
        }
      />
    </div>
  );
}

ApiLogChart.propTypes = {
  isLoading:  PropTypes.bool.isRequired,
  chartData:  PropTypes.object.isRequired
}

export default ApiLogChart;

