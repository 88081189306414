import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

// #region Pager Wrapper

///
const Pager = props => {
  return (
    <div className="pager">
      <PagingButtons
        pageNumber=         { props.pageNumber }
        pageSize=           { props.pageSize }
        totalPages=         { props.totalPages }
        pageInputRef=       { props.pageInputRef }
        pageSizeInputRef=   { props.pageSizeInputRef }
        changeState=        { props.changeState }
      />
      <br />
      <PagingInputs
        pageNumber=         { props.pageNumber }
        pageSize=           { props.pageSize }
        totalPages=         { props.totalPages }
        pageInputRef=       { props.pageInputRef }
        pageSizeInputRef=   { props.pageSizeInputRef }
        onSubmitPager=      { props.onSubmitPager }
        onKeyPressInput=    { props.onKeyPressInput }
      />
    </div>
  );
}

Pager.propTypes = {
  pageNumber:         PropTypes.number.isRequired,
  pageSize:           PropTypes.number.isRequired,
  totalPages:         PropTypes.number.isRequired,
  pageInputRef:       PropTypes.object.isRequired,
  pageSizeInputRef:   PropTypes.object.isRequired,
  onSubmitPager:      PropTypes.func.isRequired,
  onKeyPressInput:    PropTypes.func.isRequired,
  changeState:        PropTypes.func.isRequired
}

export default Pager;

// #endregion


// #region JSX

///
const PagingButtons = props => {
  return (
    <div className="paging-container">
      <Pagination>
        <PaginationItem>
          <PaginationLink
            className="page-button"
            onClick={ () => pageFirst( props.pageSize, props.pageInputRef, props.pageSizeInputRef, props.changeState ) }
            >&laquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="page-button"
            onClick={ () => pageDown( props.pageNumber, props.pageSize, props.pageInputRef, props.pageSizeInputRef, props.changeState ) }
            >&lsaquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem active>
          <PaginationLink
            className="page-button"
            >{ props.pageNumber }
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="page-button"
            onClick={ () => pageUp( props.pageNumber, props.pageSize, props.pageInputRef, props.pageSizeInputRef, props.changeState, props.totalPages ) }
            >&rsaquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="page-button"
            onClick={ () => pageLast( props.pageSize, props.pageInputRef, props.pageSizeInputRef, props.changeState, props.totalPages ) }
            >&raquo;
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
}

PagingButtons.propTypes = {
  pageNumber:         PropTypes.number.isRequired,
  pageSize:           PropTypes.number.isRequired,
  totalPages:         PropTypes.number.isRequired,
  pageInputRef:       PropTypes.object.isRequired,
  pageSizeInputRef:   PropTypes.object.isRequired,
  changeState:        PropTypes.func.isRequired
}

///
const PagingInputs = props => {
  return (
    <div id="button-container">
      <div className="spacing-left spacing-right">
        <div className="paging-container">
          <input
            id="page-input"
            ref={ props.pageInputRef }
            className="input-text"
            type="number"
            defaultValue={ props.pageNumber }
            placeholder="__"
            onChange={ props.onKeyPressInput }
            onKeyPress={ props.onKeyPressInput }
            min={ 0 }
            max={ props.totalPages - 1 }
          />
          <span>Page:</span>
        </div>
      </div>
      <div className="spacing-right">
        <div className="paging-container">
          <input
            id="size-input"
            ref={ props.pageSizeInputRef }
            className="input-text"
            type="number"
            defaultValue={ props.pageSize }
            placeholder="__"
            onChange={ props.onKeyPressInput }
            onKeyPress={ props.onKeyPressInput }
            min={ 1 }
          />
          <span>Size:</span>
        </div>
      </div>
    </div>
  );
}

PagingInputs.propTypes = {
  pageNumber:         PropTypes.number.isRequired,
  pageSize:           PropTypes.number.isRequired,
  totalPages:         PropTypes.number.isRequired,
  pageInputRef:       PropTypes.object.isRequired,
  pageSizeInputRef:   PropTypes.object.isRequired,
  onSubmitPager:      PropTypes.func.isRequired,
  onKeyPressInput:    PropTypes.func.isRequired
}

// #endregion


// #region Logic

///
const pageFirst = ( pageSize, pageInputRef, pageSizeInputRef, changeState ) => {
  setPage( 0, pageSize, pageInputRef, pageSizeInputRef, changeState );
}

///
const pageDown = ( pageNumber, pageSize, pageInputRef, pageSizeInputRef, changeState ) => {
  if( pageNumber > 0 ) {
    setPage( pageNumber - 1, pageSize, pageInputRef, pageSizeInputRef, changeState );
  }
}

///
const pageUp = ( pageNumber, pageSize, pageInputRef, pageSizeInputRef, changeState, totalPages ) => {
  if( pageNumber < totalPages - 1 ) {
    setPage( pageNumber + 1, pageSize, pageInputRef, pageSizeInputRef, changeState );
  }
}

///
const pageLast = ( pageSize, pageInputRef, pageSizeInputRef, changeState, totalPages ) => {
  setPage( totalPages - 1, pageSize, pageInputRef, pageSizeInputRef, changeState );
}

///
const setPage = ( newPage, pageSize, pageInputRef, pageSizeInputRef, changeState ) => {
  pageInputRef.current.value = +newPage;
  pageSizeInputRef.current.value = +pageSize;
  changeState( newPage, pageSize );
}

// #endregion