import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import Endpoints from '../../helpers/Endpoints';
import Utilities from '../../helpers/Utilities';
import LoginForm from '../layout/login-form/LoginForm';

// #region Wrapper

class Login extends Component {
  constructor(props) {
    super(props);
    this.emailInputRef = createRef();
    this.passwordInputRef = createRef();

    this.state = {
      pinRequired: false
    };
  }

  render = () => {
    return(
      <LoginForm
        emailInputRef=    { this.emailInputRef }
        passwordInputRef= { this.passwordInputRef }
        submitLogin=      { this.submitLogin }
        redirectPath=     { this.props.redirectPath }
        pinRequired=      { this.state.pinRequired }
      />
    );
  }

  // #endregion


  // #region User-Generated Events

  /// calls login endpoint
  submitLogin = ( redirectPath, emailInputRef, passwordInputRef ) => {
    console.log(this.state.pinRequired);
    console.log( typeof( this.setPinRequired) );
    if(this.state.pinRequired === false) {
      Endpoints.login(
        this.handleLoginResult,
        redirectPath,
        emailInputRef,
        passwordInputRef,
        this.setPinRequired
      ).catch( console.log );
    } else {
      Endpoints.pin(
        this.handleLoginResult,
        redirectPath,
        emailInputRef,
        passwordInputRef
      ).catch( console.log );
    }
  }

  /// if no authorization then show alert, else update state
  handleLoginResult = ( redirectPath, emailInputRef, passwordInputRef ) => {
  if( localStorage.getItem( 'Authorization' ) === undefined
      || localStorage.getItem( 'Authorization' ) === 'undefined' ) {
        window.alert( 'Incorrect login credentials, please re-enter your login info.' );
    }
    else {
      emailInputRef.current.value = '';
      passwordInputRef.current.value = '';
      Utilities.changeWindowLocation( redirectPath );
    }
  }

  // #endregion


  // #region Logic

  setPinRequired = value => {
    this.setState({pinRequired: value});
  }

  //#endregion

}

Login.propTypes = {
  redirectPath: PropTypes.string.isRequired
}

export default Login;




