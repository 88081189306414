import React from 'react';

import Endpoints from './Endpoints';

class Utilities extends React.Component {
  ///
  static setSessionStorageItem = ( key, value ) => {
    if( sessionStorage ) {
      sessionStorage.setItem( key, value );
    }
  }

  ///
  static dateToYYYYMMDD = date => {
    var dateTime = new Date( date );
    var year = dateTime.getFullYear();
    var month = '' + ( dateTime.getMonth() + 1 );
    var day = '' + dateTime.getDate();

    /*if ( month.length < 2 ) 
      month = '0' + month;
    if ( day.length < 2 ) 
      day = '0' + day;*/

    return [year, month, day].join( '-' );
  }

  ///
  static parseDate = dateTime => {
    return dateTime.slice( 0, 10 );
  }

  ///
  static parseAndFormatTime = dateTime => {
    return dateTime.slice( 11, 17 ).replace( ':', 'h ' ).replace( ':', 'm ' );
  }

  ///
  static updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  ///
  static initChartData = ( labels, data ) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'API Calls',
          backgroundColor: '#0077ff',
          borderWidth: 0,
          data: data
        } ]
    };
  }

  ///
  static dayDifference = ( startDate, endDate ) => {
    var date1 = new Date( startDate );
    var date2 = new Date( endDate );

    if( date1 > date2 ) {
      var temp = date1;
      date1 = date2;
      date2 = temp;
    }

    var timeDiff = date2.getTime() - date1.getTime();
    var dayDiff = timeDiff / ( 1000 * 60 * 60 * 24 );

    if( dayDiff < 0 )
      dayDiff *= -1;

    return dayDiff;
  }

  ///
  static removeDatePadding = date => {
    var parts = date.split( '-' );
    var newDate = '';

    for( var i = 0; i < parts.length; i++ )
      parts[i] = +(parts[i]);

    newDate = parts.join( '-' );
    
    return newDate;
  }

  ///
  static isValidAuthorization = async () =>
  {
    return await Endpoints.loginStatus()
      .then( loginStatus => {
        var result;
        
        if( loginStatus === false )
          result = false;
        else
          result = true;
      
        return result;
    } ).catch( console.log );
  }
  
  /// navigates to a new url
  static changeWindowLocation = to => {
    window.location.href = to;
  }

  ///
  static getSessionStorageKey = key => {
    var value = null;

    if( sessionStorage && sessionStorage.getItem( key ) !== undefined && sessionStorage.getItem( key ) !== null ) {
      value = sessionStorage.getItem( key );
    }
    
    return value;
  }

  ///
  static isSessionStorageKeyPresent = key => {
    if( sessionStorage && sessionStorage.getItem( key ) === undefined ) {
      console.log(`key: '${key}' is undefined`);
    } else if( sessionStorage && sessionStorage.getItem( key ) === null ) {
      console.log(`key: '${key}' is null`);
    } else if( sessionStorage ) {
      console.log(`key: '${key}' is ${sessionStorage.getItem( key )}`);
    }
  }

  ///
  static deleteSessionStorageKey = key => {
    var value = null;

    if( sessionStorage ) {
      sessionStorage.removeItem( key );
    }
    
    return value;
  }

  static getToken = () => {
    let token = document.cookie.split('; ').find(row => row.startsWith('PS_U_TOKEN='));
    token = token ? token.split('PS_U_TOKEN=')[1] : undefined;
    return token;
  }
}

export default Utilities;