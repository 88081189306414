import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { decodeToken } from 'react-jwt';
import { Alert } from 'reactstrap';

import BillingTable from '../layout/billing-table/BillingTable';
import Endpoints from '../../helpers/Endpoints';
import Utilities from '../../helpers/Utilities';
import MonthYearDatePicker from '../layout/month-year-date-picker/MonthYearDatePicker';
import { Months } from '../../helpers/Config';
import './Billing.scss';
import UserImpersonationInfo from '../layout/user-impersonation-info/UserImpersonationInfo';
import { ErrorMessages } from '../../helpers/Config.js';

class Billing extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this._isMounted = false;
    this.token = decodeToken( Utilities.getToken() );
    this.adminReferralInfo = JSON.parse( Utilities.getSessionStorageKey( "adminReferralInfo" ) );
    this.months = Months;
    this.years = [];

    this.state = {
      isLoading: { billing: false },
      errorMessage: { billing: '' },
      billingCycle: moment().year() + '-'
      + ( moment().month() + 1 ) + '-'
      + '1',
      items: [],
      total: {},
      month: this.months[moment().month()],
      year: { number: moment().year(), id: 0 }
    };

    // Use this when running on localhost
    // if( !this.token )
    //   this.token = decodeToken( 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDIwODU1MjkuMCwiZXhwIjoxNzA0Njc3NTI5LjAsImVtYWlsIjoiY29ubm9yY0Bwb2xsc3Rhci5jb20iLCJmaXJzdE5hbWUiOiJDb25ub3IiLCJsYXN0TmFtZSI6IkNoYXNlIiwiY2lkIjo1MzY4NDAxNywic2lkIjoyMiwibmlkIjowLCJwaWQiOjAsImlzUGFpZFN1YiI6dHJ1ZSwiaXNSZWdpc3RlcmVkIjp0cnVlLCJpc0RhdGFDbG91ZCI6dHJ1ZSwiaXNBUEkiOnRydWUsInN1YlR5cGUiOiJQb2xsc3RhciBTb2Z0d2FyZSBEZXZlbG9wZXIiLCJzdWJJZCI6MjIsImltcGVyc29uYXRpbmciOm51bGx9.DCrSS0gT1vng6vg2Sr3gx82BFKd9Wrph3GD98LjTyCo' );
    // console.log( decodeToken( 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDIwODU1MjkuMCwiZXhwIjoxNzA0Njc3NTI5LjAsImVtYWlsIjoiY29ubm9yY0Bwb2xsc3Rhci5jb20iLCJmaXJzdE5hbWUiOiJDb25ub3IiLCJsYXN0TmFtZSI6IkNoYXNlIiwiY2lkIjo1MzY4NDAxNywic2lkIjoyMiwibmlkIjowLCJwaWQiOjAsImlzUGFpZFN1YiI6dHJ1ZSwiaXNSZWdpc3RlcmVkIjp0cnVlLCJpc0RhdGFDbG91ZCI6dHJ1ZSwiaXNBUEkiOnRydWUsInN1YlR5cGUiOiJQb2xsc3RhciBTb2Z0d2FyZSBEZXZlbG9wZXIiLCJzdWJJZCI6MjIsImltcGVyc29uYXRpbmciOm51bGx9.DCrSS0gT1vng6vg2Sr3gx82BFKd9Wrph3GD98LjTyCo' ) );

    for(let i = 0; i < 10; i++) {
      this.years.push( {number: this.state.year.number - i, id: i} );
    }
  }
  
  // #endregion


  // #region Lifecycle

  render = () => {
    if( this.props.isLoggedIn === false ) {
      return <div id="billing">Please log in.</div>;
    }
    else {
      return (
        <div id="billing">
          { this.adminReferralInfo === undefined &&
            <div>
              <div className="customer-name">{this.token.firstName} {this.token.lastName}</div>
              <div className="subscription-type">{this.token.subType}</div>
            </div>
          }
          { this.adminReferralInfo !== undefined &&
            <UserImpersonationInfo adminReferralInfo={this.adminReferralInfo}></UserImpersonationInfo>
          }
          { this.state.errorMessage.billing !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.billing}</Alert> }
          <h1>Billing</h1>
          <br />
          <MonthYearDatePicker
            month=          {this.state.month}
            months=         {this.months}
            onChangeMonth=  {this.onChangeMonth}
            year=           {this.state.year}
            years=          {this.years}
            onChangeYear=   {this.onChangeYear}
          />
          <BillingTable
            items={ this.state.items }
            total={ this.state.total }
            isLoading={ this.state.isLoading.billing }
          />
          <br />
        </div>
      );
    }
  }

  ///
  componentDidUpdate = ( prevProps, prevState ) => {
    if( this.shouldRerenderData( prevProps, prevState ) ) {
      this.getCustomerData( this.state.year, this.state.month );
    }
  }

  ///
  componentDidMount = () => {
    this._isMounted = true;
    this.props.setActiveMainTab( 'Activity' );
    this.updateWindowDimensions();
    window.addEventListener( 'resize', this.updateWindowDimensions );
    window.addEventListener( 'beforeunload', this.onUnload );
    this.getCustomerData( this.state.year, this.state.month )
  }

  ///
  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener( 'resize', this.updateWindowDimensions );
    window.removeEventListener( 'beforeunload', this.onUnload );
  }
  
  // #endregion


  // #region User-Generated Events

  ///
  onUnload = event => {
    event.preventDefault();
    Utilities.setSessionStorageItem( 'billingCycle', this.state.billingCycle );
  }

  ///
  onChangeDate = date => {
  var dateString;

  if( date !== null ) {
    dateString = date.getFullYear() + '-'
      + ( date.getMonth() + 1 ) + '-'
      + 1;
  }
  else {
    dateString = moment().year() + '-'
      + ( moment().month() + 1 ) + '-'
      + '1';
  }

    this.setState({ billingCycle: dateString });
  }

  ///
  onChangeMonth = event => {
    if(event.target.value >= 0 && event.target.value < 12) {
      this.getCustomerData( this.state.year, this.months[event.target.value] );
    }
    else {
      window.alert("Error: month index out of range");
    }
  }

  ///
  onChangeYear = event => {
    if(this.years[event.target.value].number <= moment().year() && this.years[event.target.value].number > moment().year() - 10) {
      this.getCustomerData( this.years[event.target.value], this.state.month );
    }
    else {
      window.alert("Error: year index out of range");
    }
  }

  // #endregion


  // #region Api

  /// wrapper for async api calls. does state changes all at once
  getCustomerData = async ( year, month ) => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var billingCycle = this.getBillingCycleFromInputs( year.number, month.id );

    if( this.props.isLoggedIn === true ) {
      var errorMessage = { billing: '' };
      var isLoading = { billing: true };

      this.setState({ isLoading: isLoading }, 
        async () => {
          var promise = await Endpoints.getBillingSummary( billingCycle, customerId )
            .catch( exception => {
              console.log(exception);
              errorMessage = `API billing: ${ErrorMessages.generic}`;
            } );

          Promise.all([ promise ])
            .then( () => {
              if( this._isMounted ) {
                isLoading.billing = false;

                this.setState({
                  items: promise.response.items,
                  total: promise.response.total,
                  year: year,
                  month: month,
                  billingCycle: billingCycle,
                  errorMessage: errorMessage,
                  isLoading: isLoading
                });
              }
            } );
        }
      )
    }
  }

  // #endregion


  // #region Helpers

  ///
  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  }
  
  ///
  shouldRerenderData = ( prevProps, prevState ) => {
    return( this.props.isLoggedIn !== prevProps.isLoggedIn
      || this.state.billingCycle !== prevState.billingCycle );
  }

  ///
  getPickerDate = propsBillingCycle => {
    var billingCycle = new Date( propsBillingCycle );
    
    return billingCycle;
  }

  ///
  getBillingCycleFromInputs = (year, month) => {
    return year + '-' + (month + 1) + '-' + 1;
  }
  
  // #endregion
}

Billing.propTypes = {
  isLoggedIn:             PropTypes.bool.isRequired,
  setActiveMainTab:       PropTypes.func.isRequired
}

export default Billing;