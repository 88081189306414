import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

//import PollstarLogo from '../../assets/images/Pollstar-bluegold.svg';
import './Header.scss';
import Utilities from '../../helpers/Utilities';

// #region Wrapper

const Header = props => {
  return(
    <div id="header">
      {/* <div>
        <a
          id="header-logo"
          href="/"
          title="developers.pollstar.com"
          ><img src={ PollstarLogo } alt="pollstar-logo"></img>
        </a>
        <div className="spacer"></div>
        <div className="btn">
          <NavLink
            to={ props.isLoggedIn ? "/" : "/login" }
            onClick={ props.onClickLoginButton }
            >{ props.isLoggedIn === true ? "Log Out" : "Login" }
          </NavLink>
        </div>
        { sessionStorage.getItem( 'cid' ) !== undefined && sessionStorage.getItem( 'cid' ) !== null &&
          <div className="spacer"></div> }
        { sessionStorage.getItem( 'cid' ) !== undefined && sessionStorage.getItem( 'cid' ) !== null &&
          <div className="btn" onClick={ () => onClickResumeAdmin() }>Admin Panel</div> }
      </div>
      <ul id="nav-bar">
        <li title="API Info">     <NavLink  to="/api/restful"><svg className={ setMainTabClassNames( 'Restful' ) } style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z" /></svg></NavLink></li>
        <li title="Documentation">  <a        href="https://api.pollstar.com"><svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" /></svg></a></li>
        <li title="Activity">       <a        href="/activity"><svg className={ setMainTabClassNames( 'Activity' ) } style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z" /></svg></a></li>
        <li title="Billing">        <NavLink  to="/billing"><svg className={ setMainTabClassNames( 'Billing' ) } style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" /></svg></NavLink></li>
        <li title="Account">        <a        href="https://www.pollstar.com/customer/info"><svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24"><path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" /></svg></a></li>
      </ul> */}
      <ul id="nav-bar">
        { Utilities.getSessionStorageKey( 'adminReferralInfo' ) !== undefined && Utilities.getSessionStorageKey( 'adminReferralInfo' ) !== null &&
          //<li title="Admin Panel">  <a onClick={ () => onClickResumeAdmin() }>Admin Panel</a></li>
          <li title="Admin Panel">  <a        href="/admin">Admin Panel</a></li>
        }
        <li title="API Info">       <NavLink  to="/">Home</NavLink></li>
        <li title="API Info">       <NavLink  to="/api/restful">API Info</NavLink></li>
        <li title="Documentation">  <a        href="https://api.pollstar.com">Documentation</a></li>
        <li title="Activity">       <a        href="/activity">Activity</a></li>
        <li title="Billing">        <NavLink  to="/billing">Billing</NavLink></li>
        {/* <li title="Account">        <a        href="https://www.pollstar.com/customer/info">Account</a></li> */}
      </ul>
    </div>
  );
}

Header.propTypes = {
  activeMainTab:       PropTypes.string.isRequired,
  isLoggedIn:          PropTypes.bool.isRequired,
  onClickLoginButton:  PropTypes.func.isRequired
}

export default Header;

// #endregion


// #region Helpers

// ///
// const setMainTabClassNames = ( pageName, activeMainTab ) => {
//   return activeMainTab === pageName ? "material-icons active-tab" : "material-icons";
// }

///
// const onClickResumeAdmin = () => {
//   sessionStorage.removeItem( 'cid' );
//   Utilities.changeWindowLocation( '/admin' )
//   //window.location.reload();
// }

// #endregion