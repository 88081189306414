import React from 'react';
import PropTypes from 'prop-types';

import './UserImpersonationInfo.scss';

// #region Wrapper

///
const UserImpersonationInfo = props => {
  return(
    <div id="user-impersonation-info">
      { props.adminReferralInfo !== undefined && props.adminReferralInfo !== null && renderUserImpersonationInfo( props.adminReferralInfo ) }
    </div>
  );
}

UserImpersonationInfo.propsTypes = {
  adminReferralInfo:   PropTypes.object.isRequired
}

export default UserImpersonationInfo;

// #endregion


// #region JSX

///
const renderUserImpersonationInfo = adminReferralInfo => {
  return(
    <div className="info">
      <div className="impersonating">Impersonating Customer:</div>
      <div className="customer-name">{adminReferralInfo.fullName}</div>
      <div className="email">{adminReferralInfo.email}</div>
    </div>
  );
}

// #endregion


// #region Logic

// #endregion


// #region User-Generated Events

// #endregion