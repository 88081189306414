import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import _ from 'underscore';
import { decodeToken } from 'react-jwt';

import './Details.scss';
import '../../layout/endpoint-modal/EndpointModal.scss';

import Endpoints        from '../../../helpers/Endpoints';
import Utilities        from '../../../helpers/Utilities';
import ApiLogTable      from '../../layout/api-log-table/ApiLogTable';
import ApiLogList       from '../../layout/api-log-list/ApiLogList';
import ApiLogChart      from '../../layout/api-log-chart/ApiLogChart';
import Pager            from '../../layout/pager/Pager';
import EndpointModal    from '../../layout/endpoint-modal/EndpointModal';
import UserImpersonationInfo from '../../layout/user-impersonation-info/UserImpersonationInfo';
import { ErrorMessages } from '../../../helpers/Config.js';

class Details extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this._isMounted = false;
    this.pageInputRef = createRef();
    this.pageSizeInputRef = createRef();
    this.token = decodeToken( Utilities.getToken() );
    this.adminReferralInfo = JSON.parse( Utilities.getSessionStorageKey( "adminReferralInfo" ) );

    this.state = {
      pagerAlertIsOn: false,
      endpointModalIsOpen: false,
      screenWidth: 0,
      endpoint: !sessionStorage || !sessionStorage.getItem( 'endpoint' ) || sessionStorage.getItem( 'endpoint' ) === 'undefined' ?
        'ArtistSearch' : sessionStorage.getItem( 'endpoint' ),
      pageNumber: !sessionStorage || !sessionStorage.getItem( 'pageNumber_details' ) || sessionStorage.getItem( 'pageNumber_details' ) === 'undefined' ?
        0 : +sessionStorage.getItem( 'pageNumber_details' ),
      pageSize: !sessionStorage || !sessionStorage.getItem( 'pageSize_details' ) || sessionStorage.getItem( 'pageSize_details' ) === 'undefined' ?
        10 : +sessionStorage.getItem( 'pageSize_details' ),
      totalPages: Number.MAX_SAFE_INTEGER,
      pageInput: 0,
      sizeInput: 10,

      isLoading: {
        chartData: false,
        apiRoles: false,
        apiLogs: false
      },
      errorMessage: {
        chartData: '',
        apiRoles: '',
        apiLogs: ''
      },

      apiRoles: [],
      apiLogs: [],
      chartData: {}
    };
  }

  // #endregion


  // #region Lifecycle
  
  ///
  render = () => {
    if( this.props.isLoggedIn === false )
      return <div id="details">Please log in.</div>;
      //Utilities.changeWindowLocation( '/login' );
    return(
      <div id="details">
        { this.adminReferralInfo !== undefined &&
          <UserImpersonationInfo adminReferralInfo={this.adminReferralInfo}></UserImpersonationInfo>
        }
        <EndpointModal
          isLoading=    { this.state.isLoading.apiRoles }
          isOpen=       { this.state.endpointModalIsOpen }
          endpoint=     { this.state.endpoint }
          apiRoles=     { this.state.apiRoles }
          closeModal=   { this.closeEndpointModal }
          onClickItem=  { this.onClickEndpointModalItem }
        />
        { this.state.errorMessage.chartData !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.chartData}</Alert> }
        { this.state.errorMessage.apiRoles !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.apiRoles}</Alert> }
        { this.state.errorMessage.apiLogs !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.apiLogs}</Alert> }
        <h3 className="title">Details</h3>
        { <ApiLogChart chartData={ this.state.chartData } isLoading={this.state.isLoading.chartData} /> }
        <br /><br />
        <div className="endpoint-modal-container">
          <Button onClick={ this.openEndpointModal }>Select Endpoint</Button>
        </div>
        <br /><br />
        <h3>{ this.state.endpoint }</h3>
        { this.state.screenWidth >= 901 ?
          <ApiLogTable pageName={ 'details' } data={ this.state.apiLogs } isLoading={this.state.isLoading.apiLogs} /> :
          <ApiLogList pageName={ 'details' } data={ this.state.apiLogs } isLoading={this.state.isLoading.apiLogs} />
        }
        <br />
        <Pager
          pageNumber=       { this.state.pageNumber }
          pageSize=         { this.state.pageSize }
          totalPages=       { this.state.totalPages }
          pageInputRef=     { this.pageInputRef }
          pageSizeInputRef= { this.pageSizeInputRef }
          onSubmitPager=    { this.onSubmitPager }
          onKeyPressInput=  { this.onKeyPressPagerInput }
          changeState=      { this.getCustomerData }
        />
        <Alert
          style={{ opacity: this.state.pagerAlertIsOn ? 1 : 0 }}
          color="warning"
          isOpen={ true }
          toggle={ this.closePagerAlert }
          >Oops! Please enter a valid integer and try again.
        </Alert>
        <br />
      </div>
    );
  }

  ///
  componentDidUpdate = ( prevProps, prevState ) => {
    if( this.shouldRerenderData( prevProps, prevState ) )
      this.getCustomerData( this.state.pageNumber, this.state.pageSize, this.state.endpoint ).catch( console.log );
  }

  ///
  componentDidMount = () => {
    this._isMounted = true;
    this.props.setActiveMainTab( 'Activity' );
    this.props.setActiveActivityTab( 'Details' );
    this.updateWindowDimensions();
    window.addEventListener( 'resize', this.updateWindowDimensions );
    window.addEventListener( 'beforeunload', this.onUnload );
    this.getCustomerData( this.state.pageNumber, this.state.pageSize, this.state.endpoint ).catch( console.log );
  }

  ///
  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener( 'resize', this.updateWindowDimensions );
    window.removeEventListener( 'beforeunload', this.onUnload );
  }

  // #endregion


  // #region User-Generated Events

  ///
  closePagerAlert = () => {
    this.setState({ pagerAlertIsOn: false });
  }

  ///
  onKeyPressPagerInput = event => {
    if( event.key === 'Enter' )
      this.onSubmitPager();
    else if( isNaN( event.target.value ) === false || event.target.value.length === 0 ) {
      event.target.id === 'page-input' ?
        this.setState({ pageInput: +event.target.value }) :
        this.setState({ sizeInput: +event.target.value });
    }
  }

  ///
  onSubmitPager = () => {
    // If invalid input, turn on alert
    if( this.state.pageInput.length === 0 ||
        this.state.pageInput < 0 ||
        this.state.pageInput > this.state.totalPages - 1 ||
        this.state.sizeInput.length === 0 ||
        this.state.sizeInput < 1 ) {
      this.setState({ pagerAlertIsOn: true });
      setTimeout( () => { this.setState({ pagerAlertIsOn: false }) }, 3000 ); // turns off alert automatically
    }
    else
      this.getCustomerData( +this.state.pageInput, +this.state.sizeInput, this.state.endpoint, false ).catch( console.log );
  }

  ///
  openEndpointModal = () => {
    window.addEventListener( 'click', this.onClickWindow );
    this.setState({ endpointModalIsOpen: true });
  }

  ///
  closeEndpointModal = () => {
    window.removeEventListener( 'click', this.onClickWindow );
    this.setState({ endpointModalIsOpen: false });
  }

  ///
  onClickEndpointModalItem = event => {
    window.removeEventListener( 'click', this.onClickWindow );

    // Gets endpoint name from inside a div
    var innerHTML = event.currentTarget.innerHTML;
    var endpoint = innerHTML.slice( 17, innerHTML.length - 6 );

    this.pageInputRef.current.value = 0;
    this.pageSizeInputRef.current.value = 10;

    this.getCustomerData( 0, 10, endpoint ).catch( console.log );
  }

  ///
  onClickWindow = event => {
    if( event.target.className === 'endpoint-modal' )
      this.closeEndpointModal();
  }

  ///
  onUnload = event => {
    event.preventDefault();
    Utilities.setSessionStorageItem( 'endpoint', this.state.endpoint );
    Utilities.setSessionStorageItem( 'pageNumber_details', this.state.pageNumber );
    Utilities.setSessionStorageItem( 'pageSize_details', this.state.pageSize );
  }

  // #endregion


  // #region Api
  
  /// Returns an object with an error message and fetched data
  getCustomerApiLogs = async ( endpoint, startDate, endDate, page, pageSize ) => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var errorMessage = '';

    var apiLogs = await Endpoints.getLogsByEndpoint( customerId, endpoint, startDate, endDate, page, pageSize )
      .catch( exception => {
        console.log(exception);
        errorMessage = `API Logs: ${ErrorMessages.generic}`;
      } );

    return { errorMessage: errorMessage, apiLogs: apiLogs.response };
  }

  /// Returns an object with an error message and fetched data
  getCustomerChartData = async endpoint => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var errorMessage = '';

    var detailsChartData = await Endpoints.getDetailsChartData( endpoint, customerId, this.props.startDate, this.props.endDate )
      .catch( exception => {
        console.log(exception);
        errorMessage = `Chart Data: ${ErrorMessages.generic}`;
      } );

    var sortedChartData = _.sortBy( detailsChartData.response, 'date' );
    var labels = this.getPreviousDates( Utilities.dayDifference( this.props.startDate, this.props.endDate ) );
    labels = labels.reverse();
    
    return { errorMessage: errorMessage, chartData: this.createChartData( labels, sortedChartData ) };
  }

  /// Returns an object with an error message and fetched data
  getApiRoles = async () => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var errorMessage = '';
    
    var apiRoles = await Endpoints.getApiRoles( customerId )
      .catch( exception => {
        console.log(exception);
        errorMessage = `API Roles: ${ErrorMessages.generic}`;
      } );

    return { errorMessage: errorMessage, apiRoles: apiRoles.response };
  }

  /// Wrapper for async api calls. does state changes all at once
  getCustomerData = async ( page, pageSize, endpoint = '', includeChartData = true ) => {
    if( this.props.isLoggedIn ) {
      if( endpoint === '' )
        endpoint = this.state.endpoint;

      var chartDataResult = { errorMessage: '', chartData: this.state.chartData };
      var apiRolesResult =  { errorMessage: '', apiRoles: this.state.apiRoles };
      var apiLogsResult =   { errorMessage: '', apiLogs: this.state.apiLogs };
      var isLoading = {
        chartData: includeChartData,
        apiRoles: apiRolesResult.apiRoles.length <= 0,
        apiLogs: true
      };
      var errorMessage = {
        chartData: '',
        apiRoles: '',
        apiLogs: ''
      };

      // Update this.state.isLoading, then fetch the data
      this.setState({ isLoading: isLoading }, 
        async () => {
          apiLogsResult = await this.getCustomerApiLogs( endpoint, this.props.startDate, this.props.endDate, page, pageSize );

          // Prevents updating chart data when using the pager
          if( includeChartData )
            chartDataResult = await this.getCustomerChartData( endpoint );

          // Updates api roles when logging in from the details page
          if( apiRolesResult.apiRoles.length <= 0 )
            apiRolesResult = await this.getApiRoles();

          isLoading = {
            chartData: false,
            apiRoles: false,
            apiLogs: false
          };
          errorMessage = {
            chartData: chartDataResult.errorMessage,
            apiRoles: apiRolesResult.errorMessage,
            apiLogs: apiLogsResult.errorMessage
          };

          // Prevents crash when navigating away before async calls return
          Promise.all([ chartDataResult, apiRolesResult, apiLogsResult ])
            .then( () => {
              if( this._isMounted )
                Utilities.setSessionStorageItem( 'endpoint', endpoint );
                Utilities.setSessionStorageItem( 'pageNumber_details', page );
                Utilities.setSessionStorageItem( 'pageSize_details', pageSize );

                this.setState({
                  chartData: chartDataResult.chartData,
                  apiRoles: apiRolesResult.apiRoles,
                  apiLogs: apiLogsResult.apiLogs.logs,
                  endpoint: endpoint,
                  pageNumber: page,
                  pageInput: page,
                  pageSize: pageSize,
                  sizeInput: pageSize,
                  totalPages: apiLogsResult.apiLogs.totalPages,
                  endpointModalIsOpen: false,
                  isLoading: isLoading,
                  errorMessage: errorMessage
                });
            } )
            .catch( console.log );
        }
      );
    }
  }

  // #endregion


  // #region Helpers

  /// Allows chart to resize with the window
  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  }
  
  /// Gets labels for chart
  getPreviousDates = numDays => {
    var endDate = new Date( this.props.endDate );
    
    if( this.props.startDate > this.props.endDate )
      endDate = new Date( this.props.startDate );  
    
    var dates = [];

    for( var i = 0; i < numDays; i++ )
      dates.push( Utilities.dateToYYYYMMDD( new Date( endDate ).setDate( endDate.getDate() - i ) ) );

    return dates;
  }

  ///
  createChartData = ( labels, data ) => {
    var newData = new Array( labels.length );
    var j = 0;
    
    ///TODO: explain
    for( var i = 0; i < labels.length; i++ ) {
      if( j < data.length && labels[i] === Utilities.removeDatePadding( data[j].date ) ) {
        newData[i] = data[j].totalCalls;
        j++;
      }
      else
        newData[i] = 0;
    }

    return Utilities.initChartData( labels, newData );
  }
  
  ///
  shouldRerenderData = ( prevProps, prevState ) => {
    return (
      this.props.startDate  !== prevProps.startDate ||
      this.props.endDate    !== prevProps.endDate ||
      this.props.plotBy     !== prevProps.plotBy ||
      this.props.isLoggedIn !== prevProps.isLoggedIn ||
      this.state.endpoint   !== prevState.endpoint ||
      this.state.pageNumber !== prevState.pageNumber ||
      this.state.pageSize   !== prevState.pageSize
    );
  }

  // #endregion
}

Details.propTypes = {
  startDate:              PropTypes.string.isRequired,
  endDate:                PropTypes.string.isRequired,
  plotBy:                 PropTypes.string.isRequired,
  isLoggedIn:             PropTypes.bool.isRequired,
  setActiveMainTab:       PropTypes.func.isRequired,
  setActiveActivityTab:   PropTypes.func.isRequired
}

export default Details;
