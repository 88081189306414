import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'reactstrap';
import { decodeToken } from 'react-jwt';
import { Alert } from 'reactstrap';

import Endpoints from '../../../helpers/Endpoints';
import Utilities from '../../../helpers/Utilities';
import './Restful.scss';
import UserImpersonationInfo from '../../layout/user-impersonation-info/UserImpersonationInfo';
import { ErrorMessages } from '../../../helpers/Config.js';
import LoadingSpinner from '../../layout/loading-spinner/LoadingSpinner.js';

class Restful extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this._isMounted = false;
    this.token = decodeToken( Utilities.getToken() );
    this.adminReferralInfo = JSON.parse( Utilities.getSessionStorageKey( "adminReferralInfo" ) );
  
    this.state = {
      idTooltipIsOpen: false,
      secretTooltipIsOpen: false,
      id: '_',
      secret: '_',

      isLoading: {
        apiKeys: false,
        apiRoles: false
      },
      errorMessage: {
        apiKeys: '',
        apiRoles: ''
      },

      apiRoles: []
    }
  }
  
  // #endregion


  // #region Lifecycle

  render = () => {
    if( this.props.isLoggedIn === false )
      return <div id="restful">Please log in.</div>;
    return(
      <div id="restful">
        { this.adminReferralInfo !== undefined &&
          <UserImpersonationInfo adminReferralInfo={this.adminReferralInfo}></UserImpersonationInfo>
        }
        { this.state.errorMessage.apiKeys !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.apiKeys}</Alert> }
        { this.state.errorMessage.apiRoles !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.apiRoles}</Alert> }
        <h1>API Configuration</h1>
        <br />
        { this.props.isLoggedIn === true && this.state.apiRoles !== undefined && this.state.apiRoles.length === 0 && 
          <div>NOTE: Please sign up for api access <a href="https://pages.pollstar.com/api-sign-up">here</a>.</div> }
        <h2>Restful API</h2>
        <br />

        <ul id="api-notes">
          <li
            >Please read the &nbsp;
            <a
              href="../../../ApiTermsConditions.pdf"
              alt="api-terms"
              target="_blank"
              >API terms and conditions
            </a>
            &nbsp; before using the the API
          </li>
          <li>
            Refer to the full &nbsp;
            <a
              href="https://api.pollstar.com"
              alt="api-docs"
              >API documentation
            </a>
            , or contact us through our &nbsp;
            <a
              href="https://pollstar.freshdesk.com/support/home"
              alt="pollstar.freshdesk.com/support/home"
              >support page
            </a>
            &nbsp; for help
          </li>
          <li> For more info on API data definitions, see our <a href="https://pages.pollstar.com/data-dictionary">Data Dictionary</a> page.</li>
        </ul>
        <br />
        <br />
        { this.props.isLoggedIn === true && <div>
          <div id="client-info-container">
            <Button onClick={ this.onClickRegenKey }>Re-Generate Keys</Button>
            <br /><br />
            <div>
              <h5 className="squeeze-left">&gt;&nbsp;&nbsp;</h5>
              <h5
                id="id-label"
                className="key-label"
                onClick={ () => this.copyClientKey( 'Id' ) }
                >Client ID
              </h5>
              <h5 className="squeeze-right">&nbsp;&nbsp;&lt;</h5>
            </div>
            <input
              id="client-id"
              className="client-key"
              type="text"
              readOnly="readonly"
              value={ this.state.id }
            />
            <br /><br />
            <div>
              <h5 className="squeeze-left">&gt;&nbsp;&nbsp;</h5>
              <h5
                id="secret-label"
                className="key-label"
                onClick={ () => this.copyClientKey( 'Secret' ) }
                >Client Secret
              </h5>
              <h5 className="squeeze-right">&nbsp;&nbsp;&lt;</h5>
            </div>
            <input
              id="client-secret"
              className="client-key"
              type="text"
              readOnly="readonly"
              value={ this.state.secret }
            />
            <br />
          </div>
          <Tooltip 
            id="id-tooltip"
            placement="top" 
            isOpen={ this.state.idTooltipIsOpen } 
            delay={ { show: 0, hide: 500 } }
            autohide={ false } 
            target="id-label" 
            toggle={ this.toggleId }
            fade={ true }>
            Click to copy
          </Tooltip>
          <Tooltip 
            id="secret-tooltip"
            placement="top"
            isOpen={ this.state.secretTooltipIsOpen }
            delay={ { show: 0, hide: 500 } }
            autohide={ false }
            target="secret-label"
            toggle={ this.toggleSecret }
            fade={ true }>
            Click to copy
          </Tooltip>
          <br />
          
          <h3>Available Endpoints</h3>
          <ul id="endpoints">
            { this.props.isLoggedIn &&
              ( this.state.apiRoles === undefined ?
                console.log( 'apiRoles is undefined' ) :
                this.state.apiRoles.map( apiRole =>
                  <li
                    onClick={ () => window.location.href = apiRole.documentationURL }
                    title={ apiRole.documentationURL }
                    key={ apiRole.id }>
                      <div href={ apiRole.documentationURL }>{ apiRole.name }</div>
                  </li> )
              )
            }
            <LoadingSpinner isLoading={this.state.isLoading.apiRoles} dimBackground={true}></LoadingSpinner>
          </ul>
          <br />
        </div>}
      </div>
    );
  }

  ///
  componentDidUpdate = prevProps => {
    if( this.props.isLoggedIn  !== prevProps.isLoggedIn )
      this.getCustomerData();
  }
  
  ///
  componentDidMount = () => {
    this._isMounted = true;
    this.props.setActiveMainTab( 'Restful' );
    
    this.getCustomerData().catch( console.log );
  }

  ///
  componentWillUnmount = () => {
    this._isMounted = false;
  }
  
  // #endregion


  // #region User-Generated Events

  ///
  toggleId = () => {
    this.setState({ idTooltipIsOpen: !this.state.idTooltipIsOpen });
  }

  ///
  toggleSecret = () => {
    this.setState({ secretTooltipIsOpen: !this.state.secretTooltipIsOpen });
  }

  ///
  copyClientKey = key => {
    var copyText, tooltip;

    if( key === 'Id' ) {
      copyText = document.getElementById( "client-id" );
      tooltip = document.getElementById( "id-tooltip" );
    }
    else if( key === 'Secret') {
      copyText = document.getElementById( "client-secret" );
      tooltip = document.getElementById( "secret-tooltip" );
    }

    copyText.select();
    copyText.setSelectionRange( 0, 99999 );
    document.execCommand( "copy" );
    tooltip.innerHTML = key + " copied!";
  }

  ///
  onClickRegenKey = () => {
    this.getCustomerData( true );
  }
  
  // #endregion


  // #region Api

  ///
  getCustomerData = async ( shouldRegenerateKey = false ) => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var isLoading = { 
      apiKeys: true,
      apiRoles: true 
    };
    var errorMessage = { 
      apiKeys: '' ,
      apiRoles: ''
    };
    
    if( this.props.isLoggedIn ) {
      this.setState({ isLoading: isLoading }, 
        async () => {
          var keys = await Endpoints.getClientKeys( shouldRegenerateKey )
            .catch( exception => {
              console.log(exception);
              errorMessage.apiKeys = `API Keys: ${ErrorMessages.generic}`;
            } );

          var roles = await Endpoints.getApiRoles( customerId )
            .catch( exception => {
              console.log(exception);
              errorMessage.apiRoles = `API Logs: ${ErrorMessages.generic}`;
            } );

          Promise.all([ keys, roles ])
          .then( () => {
            if( this._isMounted )
              isLoading.apiKeys = false;
              isLoading.apiRoles = false;

              this.setState({
                id: keys.response.id,
                secret: keys.response.secret,
                apiRoles: roles.response,
                isLoading: isLoading,
                errorMessage: errorMessage
              });
          } );
        }
      )
    }
  }
  
  // #endregion
}

Restful.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setActiveMainTab: PropTypes.func.isRequired
}

export default Restful;