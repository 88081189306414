import React from 'react';
import request from 'superagent';

import { UrlDomains } from './Config';
import Utilities from './Utilities';
//import { Utilities } from '../helpers/Utilities';

class Endpoints extends React.Component {
  // #region Login|Status|Logout

  // calls '/data/v1/customer/login'
  static login = async ( handleLoginResult, redirectPath, emailInputRef, passwordInputRef, setPinRequired, remember = false ) => {
    const body = {
      email: emailInputRef.current.value,
      password: passwordInputRef.current.value,
      remember: remember,
      feature: 'developers.pollstar.com'
    };

    return await request.agent()
      .post( UrlDomains.apiDomain + '/data/v1/customer/login' )
      .set( 'Content-Type', 'application/json' )
      .set( 'withCredentials', 'true' )
      .send( body )
      .then( response => {
        //localStorage.setItem( 'Authorization', response.headers['authorization'] );
        handleLoginResult( redirectPath, emailInputRef, passwordInputRef );
      } )
      .catch( response => {
        if(response.status === 400) {
          passwordInputRef.current.value = '';
          setPinRequired(true);
        }
      })
  }

  // call '/data/v1/customer/pin'
  static pin = async ( handleLoginResult, redirectPath, emailInputRef, passwordInputRef ) => {
    const body = {
      email: emailInputRef.current.value,
      pin: passwordInputRef.current.value,
      feature: 'developers.pollstar.com'
    };

    return await request.agent()
      .post( UrlDomains.apiDomain + '/data/v1/customer/pin' )
      .set( 'Content-Type', 'application/json' )
      .set( 'withCredentials', 'true' )
      .send( body )
      .then( response => {
        //localStorage.setItem( 'Authorization', response.headers['authorization'] );
        handleLoginResult( redirectPath, emailInputRef, passwordInputRef );
      } )
      .catch( console.log );
  }

  // calls '/data/v1/customer/loginStatus'
  static loginStatus = async () => {
    return await request.agent()
      .get( UrlDomains.apiDomain + '/data/v1/customer/loginStatus' )
      .set( 'Content-Type', 'application/json' )
      .set( 'withCredentials', 'true' )
      //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
      .set( 'Authorization', Utilities.getToken() )
      .then( response => {
        //localStorage.setItem( 'Authorization', response.headers['authorization'] );
        return response.body.isRegistered;
      } )
      .catch( console.log );
  }

  // calls '/data/v1/customer/logout'
  static logout = async () => {
    return await request.agent()
      .get( UrlDomains.apiDomain + '/data/v1/customer/logout' )
      .set( 'Content-Type', 'application/json' )
      .set( 'withCredentials', 'true' )
      //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
      .set( 'Authorization', Utilities.getToken() )
      .catch( console.log );
;  }

  // calls '/data/v1/customer/api-signup'
  static signup = async ( name, email, phone, useCase, dataRequested ) => {
    const body = {
      name: name,
      email: email,
      phone: phone,
      useCase: useCase,
      dataRequested: dataRequested
    };

    return await request.agent()
      .post( UrlDomains.apiDomain + '/data/v1/customer/api-signup' )
      .set( 'Content-Type', 'application/json' )
      .set( 'withCredentials', 'true' )
      .send( body )
      .then( response => {
        //localStorage.setItem( 'Authorization', response.headers['authorization'] );
        return response.status;
      } )
      .catch( console.log );
  }

  // #endregion


  // #region Customer

  // calls '/data/v1/customer/api-roles'
  static getApiRoles = async customerId => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/customer/api-roles' )
        .query({ customerId })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // calls '/data/v1/customer/client-keys'
  static getClientKeys = async ( shouldRegenerateKey = false ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/customer/client-keys' )
        .query({ shouldRegenerateKey })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // calls '/data/v1/admin/api-customers'
  static getApiCustomers = async ( page = 0, pageSize = 10 ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/admin/api-customers' )
        .query({ page })
        .query({ pageSize })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // #endregion


  // #region Logs

  // calls '/data/v1/logs/api-summaries'
  static getApiSummaries = async ( customerId = 0, endpoint = 'all', startDate = '', endDate = '' ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/logs/api-summaries' )
        .query({ customerId })
        .query({ endpoint })
        .query({ startDate })
        .query({ endDate })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // calls '/data/v1/logs/endpoint-logs'
  static getLogsByEndpoint = async ( customerId = 0, endpoint = '', startDate = '', endDate = '', page = 0, pageSize = 10 ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/logs/endpoint-logs' )
        .query({ customerId })
        .query({ endpoint })
        .query({ startDate })
        .query({ endDate })
        .query({ page })
        .query({ pageSize })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // calls '/data/v1/logs/details-chart-data'
  static getDetailsChartData = async ( endpoint, customerId = 0, startDate = '', endDate = '' ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/logs/details-chart-data' )
        .query({ endpoint })
        .query({ customerId })
        .query({ startDate })
        .query({ endDate })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // calls '/data/v1/logs/all-logs'
  static getAllApiLogs = async ( startDate, endDate, customerId = 0, page = 0, pageSize = 10 ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/logs/all-logs' )
        .query({ startDate })
        .query({ endDate })
        .query({ customerId })
        .query({ page })
        .query({ pageSize })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // #endregion


  // #region Billing

  // calls '/data/v1/logs/billing-summary'
  static getBillingSummary = async ( fromDate, customerId = 0 ) => {
    return new Promise( resolve => {
      request.agent()
        .get( UrlDomains.apiDomain + '/data/v1/logs/billing-summary' )
        .query({ fromDate })
        .query({ customerId })
        .set( 'Content-Type', 'application/json' )
        .set( 'withCredentials', 'true' )
        //.set( 'Authorization', localStorage.getItem( 'Authorization' ) )
        .set( 'Authorization', Utilities.getToken() )
        .then( response => {
          //localStorage.setItem( 'Authorization', response.headers['authorization'] );
          resolve({ response: response.body })
        } )
        .catch( console.log );
    } )
  }

  // #endregion
}

export default Endpoints;