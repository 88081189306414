import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardImg, CardBody } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';

import Banner       from '../../assets/images/api-banner-big.png';
import ApiAccessImg from '../../assets/images/api-access.png';
import ApiInfoImg from '../../assets/images/api-info.jpg';
import DocsImg      from '../../assets/images/docs.png';
import ActivityImg  from '../../assets/images/activity.png';
import BillingImg  from '../../assets/images/billing.png';
import DataDictionaryImg  from '../../assets/images/data-dictionary.png';
import ApiUpdatesImg  from '../../assets/images/api-updates.jpg';
import './Home.scss';
import Utilities from '../../helpers/Utilities';

class Home extends React.Component {
  // #region Lifecycle

  /// renders banner, welcome text, and cards
  render = () => {
    return (
      <div id="home">
        <div id="banner"><img src={ Banner } alt="home-banner" /></div>
        <br />
        <h1>Welcome to the Pollstar Developer Portal</h1>
        <br />
        <p style={{ maxWidth: "1000px" }}>
          The Pollstar API provides a powerful interface to Pollstar's data. Some example use cases 
          include finding upcoming events in a certain area or venue, or to getting detailed event 
          and ticketing info.
        </p>
        <br /><br />

        <div id="card-contain">
          {/* <Card onClick={ () => Utilities.changeWindowLocation( '/sign-up' ) } title="https://developers.pollstar.com/sign-up">
            <CardImg src={ ApiAccessImg } alt="api-image" />
            <CardBody><CardTitle>API Access</CardTitle></CardBody>
          </Card> */}
          <Card onClick={ () => Utilities.changeWindowLocation( 'https://pages.pollstar.com/api-sign-up' ) } title="https://pages.pollstar.com/api-sign-up">
            <CardImg src={ ApiAccessImg } alt="api-image" />
            <CardBody><CardTitle>API Access</CardTitle></CardBody>
          </Card>

          <Card onClick={ () => Utilities.changeWindowLocation( '/api/restful' ) } title="https://developers.pollstar.com/api/restful">
            <CardImg src={ ApiInfoImg } alt="api-image" />
            <CardBody><CardTitle>API Info</CardTitle></CardBody>
          </Card>
          
          <Card onClick={ () => Utilities.changeWindowLocation( 'https://api.pollstar.com' ) } title="https://api.pollstar.com">
            <CardImg src={ DocsImg } alt="docs-image" />
            <CardBody><CardTitle>Docs</CardTitle></CardBody>
          </Card>
               
          <Card onClick={ () => Utilities.changeWindowLocation( '/activity' ) } title="https://developers.pollstar.com/activity">
            <CardImg src={ ActivityImg } alt="activity-image" />
            <CardBody><CardTitle>Activity</CardTitle></CardBody>
          </Card>

          <Card onClick={ () => Utilities.changeWindowLocation( '/billing' ) } title="https://developers.pollstar.com/billing">
            <CardImg src={ BillingImg } alt="billing-image" />
            <CardBody><CardTitle>Billing</CardTitle></CardBody>
          </Card>

          <Card onClick={ () => Utilities.changeWindowLocation( 'https://pages.pollstar.com/data-dictionary' ) } title="https://pages.pollstar.com/data-dictionary">
            <CardImg src={ DataDictionaryImg } alt="data-dictionary-image" />
            <CardBody><CardTitle>Data Dictionary</CardTitle></CardBody>
          </Card>

          <Card onClick={ () => Utilities.changeWindowLocation( 'https://pages.pollstar.com/api-updates' ) } title="https://pages.pollstar.com/api-updates">
            <CardImg src={ ApiUpdatesImg } alt="api-updates-image" />
            <CardBody><CardTitle>API Updates</CardTitle></CardBody>
          </Card>
        </div>
        <br /><br />
      </div>
    );
  }
  
  /// sets active header tab
  componentDidMount = () => {
    this.props.setActiveMainTab( 'Home' );
  }

  // #endregion
}

Home.propTypes = {
  isLoggedIn:         PropTypes.bool.isRequired,
  setActiveMainTab:   PropTypes.func.isRequired
}

export default Home;