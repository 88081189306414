import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

// Keep this for debugging
//import { decodeToken } from 'react-jwt'

import Endpoints    from '../../helpers/Endpoints';
import AdminTable from '../layout/admin-table/AdminTable';
import './Admin.scss';
import Utilities from '../../helpers/Utilities';
import { ErrorMessages } from '../../helpers/Config.js';

class Admin extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this._isMounted = false;

    this.state = {
      isLoading: {
        apiCustomers: false
      },
      errorMessage: {
        apiCustomers: ''
      },

      customerData: []
    }
  }
  
  // #endregion


  // #region Lifecycle

  ///
  render = () => {
    if( this.props.isLoggedIn === false )
      return <div id="admin">Please log in.</div>;
    return(
      <div id="admin">
        <br />
        <div>
          <div>When impersonating a customer, you will have access to:</div>
          <div><b>"/billing"</b>: billing breakdown</div>
          <div><b>"/api/restful"</b>: endpoint permissions (NOT client secret or client id)</div>
          <div><b>"/activity/summary"</b>: which endpoints they've called and how much</div>
          <div><b>"/activity/details"</b>: usage stats for a specific endpoint</div>
          <div><b>"/activity/history"</b>: chronological list of calls</div>
        </div>
        { this.state.errorMessage.apiCustomers !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.apiCustomers}</Alert> }
        <br />
        <AdminTable customerData={ this.state.customerData } isLoading={this.state.isLoading.apiCustomers} />
        <br />
      </div>
    );
  }

  ///
  componentDidUpdate = prevProps => {
    if( this.props.isLoggedIn  !== prevProps.isLoggedIn )
      this.getCustomerData();
  }

  ///
  componentDidMount = () => {
    this._isMounted = true;
    
    // Keep this for debugging
    //console.log( decodeToken( Utilities.getToken() ) );

    Utilities.deleteSessionStorageKey( 'adminReferralInfo' );
    this.getCustomerData().catch( console.log );
  }

  ///
  componentWillUnmount = () => {
    this._isMounted = false;
  }
  
  // #endregion


  // #region Api

  ///
  getCustomerData = async ( page = 0, pageSize = 100 ) => {
    if( this.props.isLoggedIn ) {
      var errorMessage = { apiCustomers: '' };
      var isLoading = { apiCustomers: true };

      this.setState({ isLoading: isLoading }, 
        async () => {
          var customers = await Endpoints.getApiCustomers( page, pageSize )
            .catch( exception => {
              console.log(exception);
              errorMessage = `API Customers: ${ErrorMessages.generic}`;
            } );

          Promise.all([ customers ])
          .then( () => {
            if( this._isMounted )
              isLoading.apiCustomers = false;

              this.setState({ customerData: customers.response.customers, errorMessage: errorMessage, isLoading: isLoading });
          } )
          .catch( console.log );
        }
      )
    }
  }

  // #endregion 
}

Admin.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export default Admin;