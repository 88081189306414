import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import _ from 'underscore';
import { decodeToken } from 'react-jwt';

import Endpoints from '../../../helpers/Endpoints';
import Utilities from '../../../helpers/Utilities';
import ApiLogTable from '../../layout/api-log-table/ApiLogTable';
import ApiLogList from '../../layout/api-log-list/ApiLogList';
import './Summary.scss';
import UserImpersonationInfo from '../../layout/user-impersonation-info/UserImpersonationInfo';
import { ErrorMessages } from '../../../helpers/Config.js';
import { Alert } from 'reactstrap';

class Summary extends React.Component {
  // #region Constructor

  constructor( props ) {
    super( props );
    this._isMounted = false;
    this.token = decodeToken( Utilities.getToken() );
    this.adminReferralInfo = JSON.parse( Utilities.getSessionStorageKey( "adminReferralInfo" ) );

    this.state = {
      isLoading: {
        summaries: false
      },
      errorMessage: {
        summaries: ''
      },

      screenWidth: 0,
      chartData: {},
      summaries: []
    };
  }

  // #endregion


  // #region Lifecycle
  
  ///react lifecycle method
  render = () => {
    if( this.props.isLoggedIn === false )
      return <div id="summary">Please log in.</div>;
    return (
      <div id="summary">
        { this.adminReferralInfo !== undefined &&
          <UserImpersonationInfo adminReferralInfo={this.adminReferralInfo}></UserImpersonationInfo>
        }
        { this.state.errorMessage.summaries !== '' && <Alert color="danger" isOpen={ true }>{this.state.errorMessage.summaries}</Alert> }
        <h3>Summary</h3>
        <div className="chart-container">
          <Bar
            id="top10-bar"
            data={ this.state.chartData }
            options={{
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "Endpoint Usage",
                fontSize: 28,
                fontFamily: "'Rubik', sans-serif",
                fontColor: "#3b5066"
              },
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  fontStyle: "'Rubik', sans-serif",
                  fontColor: "#3b5066",
                  fontSize: 24
              }},
              scales: {
                xAxes: [{
                  gridLines: {
                    display: false
                }}],
                yAxes: [{
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    beginAtZero: true,
                    precision: 0
              }}]},
              tooltips: {
                enabled: false
              }}
            }
          />
        </div>
        <br /><br />
        { this.state.screenWidth >= 901 ?
          <ApiLogTable pageName={ 'summary' } data={ this.state.summaries } isLoading={this.state.isLoading.summaries} /> :
          <ApiLogList pageName={ 'summary' } data={ this.state.summaries } isLoading={this.state.isLoading.summaries} />
        }
        { this.state.screenWidth >= 901 && <br /> }
      </div>
    );
  }

  ///react lifecycle method
  componentDidMount = () => {
    this._isMounted = true;
    this.props.setActiveMainTab( 'Activity' );
    this.props.setActiveActivityTab( 'Summary' );
    this.updateWindowDimensions();
    window.addEventListener( 'resize', this.updateWindowDimensions );
    this.getCustomerData().catch( console.log );
  }
  
  ///react lifecycle method
  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener( 'resize', this.updateWindowDimensions );
  }

  ///
  componentDidUpdate = ( prevProps, prevState ) => {
    if( this.shouldRerenderData( prevProps, prevState ) ) {
      this.getCustomerData();
    }
  }

  // #endregion


  // #region Api

  ///
  getCustomerData = async () => {
    var customerId = this.adminReferralInfo ? this.adminReferralInfo.customerId : this.token.cid;
    var endpoint = "all";
    var errorMessage = '';
    var isLoading = { summaries: true };

    this.setState({ isLoading: isLoading }, 
      async () => {
        var summaries = await Endpoints.getApiSummaries( customerId, endpoint, this.props.startDate, this.props.endDate )
          .catch( exception => {
            console.log(exception);
            errorMessage = `API Summaries: ${ErrorMessages.generic}`;
          } );

        this.processApiSummaries( summaries.response, errorMessage );
      }
    )
  }

  // #endregion


  // #region Helpers

  ///updates state.screenWidth to re-render chart with correct width
  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  ///sets all dynamic chart data
  createChartData = summaries => {
    const labels = summaries.map( summary => summary.endpoint );
    const data = summaries.map( summary => summary.totalCalls );
    const newChartData = {
      labels: labels,
      datasets: [
        {
          label: 'API Calls',
          backgroundColor: '#0077ff',
          borderWidth: 0,
          data: data
        } ]
    };
    
    return newChartData;
  }

  ///
  shouldRerenderData = ( prevProps, prevState ) => {
    return (
      this.props.startDate  !== prevProps.startDate ||
      this.props.endDate    !== prevProps.endDate ||
      this.props.plotBy     !== prevProps.plotBy ||
      this.props.isLoggedIn !== prevProps.isLoggedIn ||
      this.state.endpoint   !== prevState.endpoint ||
      this.state.pageNumber !== prevState.pageNumber ||
      this.state.pageSize   !== prevState.pageSize
    );
  }

  ///
  processApiSummaries = ( apiSummaries, summariesErrorMessage ) => {
    if( this._isMounted ) {
      var isLoading = { summaries: false };
      var errorMessage = { summaries: summariesErrorMessage };

      const sortedSummaries = _.sortBy( apiSummaries, 'totalCalls' ).reverse();
      const newChartData = this.createChartData( sortedSummaries );
      
      this.setState({ summaries: sortedSummaries, chartData: newChartData, errorMessage: errorMessage, isLoading: isLoading });
    }
  }

  // #endregion
}

Summary.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  plotBy: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  setActiveMainTab: PropTypes.func.isRequired,
  setActiveActivityTab: PropTypes.func.isRequired
}

export default Summary;