import React from 'react';
import { pdfjs } from 'react-pdf';

import './Footer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// #region Wrapper

const Footer = props => {
  return (
    <div id="footer">
      <hr />
      <br />
      { footerResources() }
      { footerSocials() }
      <div>© Copyright 1999-{ new Date().getFullYear() } Pollstar. All Rights Reserved.</div>
      <div>
        <a
          href="https://www.pollstar.com/license-agreement-2"
          alt="eula"
          >License Agreement
        </a>
      </div>
      <br />
    </div>
  );
}

export default Footer;

// #endregion


// #region JSX

///
const footerResources = () => {
  return(
    <ul id="resources">
      <li>
        <a
          href="https://www.pollstar.com/about-us"
          alt="about-us"
          >About Us
        </a>
      </li>
      <li>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</li>
      <li>
        <a
          href="https://pollstar.freshdesk.com/"
          alt="support"
          >Support
        </a>
      </li>
      <li>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</li>
      <li>
        <a
          href="../../ApiTermsConditions.pdf"
          alt="api-terms-and-conditions"
          target="_blank"
          >Terms and Conditions
        </a>
      </li>
      <li>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</li>
      <li>
        <a
          href="https://www.pollstar.com/privacy-policy"
          alt="privacy-policy"
          >Privacy
        </a>
      </li>
      <li>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</li>
      <li>
        <a
          href="https://api.pollstar.com"
          alt="docs"
          >Docs
        </a>
      </li>
    </ul>
  );
}

///
const footerSocials = () => {
  return(
    <ul id="socials">
      <li title="Facebook">
        <a
          href="https://www.facebook.com/pollstar"
          alt="facebook"
          ><svg viewBox="0 0 24 24"><path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" /></svg>
        </a>
      </li>
      <li title="Twitter">
        <a
          href="https://twitter.com/pollstar"
          alt="twitter"
          ><svg viewBox="0 0 24 24"><path fill="currentColor" d="M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
        </a>
      </li>
      <li title="YouTube">
        <a
          href="https://www.youtube.com/user/PollstarStudios"
          alt="youtube"
          ><svg viewBox="0 0 24 24"><path fill="currentColor" d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" /></svg>
        </a>
      </li>
      <li title="LinkedIn">
        <a
          href="https://www.linkedin.com/company/pollstar"
          alt="linkedin"
          ><svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" /></svg>
        </a>
      </li>
      <li title="Instagram">
        <a
          href="https://www.instagram.com/pollstar_official/"
          alt="instagram"
          ><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" /></svg>
        </a>
      </li>
      <li title="Pinterest">
        <a
          href="https://www.pinterest.com/ConcertHotwire/"
          alt="pinterest"
          ><svg viewBox="0 0 24 24"><path fill="currentColor" d="M9.04,21.54C10,21.83 10.97,22 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12C2,16.25 4.67,19.9 8.44,21.34C8.35,20.56 8.26,19.27 8.44,18.38L9.59,13.44C9.59,13.44 9.3,12.86 9.3,11.94C9.3,10.56 10.16,9.53 11.14,9.53C12,9.53 12.4,10.16 12.4,10.97C12.4,11.83 11.83,13.06 11.54,14.24C11.37,15.22 12.06,16.08 13.06,16.08C14.84,16.08 16.22,14.18 16.22,11.5C16.22,9.1 14.5,7.46 12.03,7.46C9.21,7.46 7.55,9.56 7.55,11.77C7.55,12.63 7.83,13.5 8.29,14.07C8.38,14.13 8.38,14.21 8.35,14.36L8.06,15.45C8.06,15.62 7.95,15.68 7.78,15.56C6.5,15 5.76,13.18 5.76,11.71C5.76,8.55 8,5.68 12.32,5.68C15.76,5.68 18.44,8.15 18.44,11.43C18.44,14.87 16.31,17.63 13.26,17.63C12.29,17.63 11.34,17.11 11,16.5L10.33,18.87C10.1,19.73 9.47,20.88 9.04,21.57V21.54Z" /></svg>
        </a>
      </li>
    </ul>
  );
}

// #endregion