import React from 'react';
import PropTypes from 'prop-types';

import './BillingTable.scss';

import { Columns } from '../../../helpers/Config';
import Utilities from '../../../helpers/Utilities';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

// #region Wrapper

///
const BillingTable = props => {
  return(
    <div id="billing-table">
      { renderBillingTable( props.items, props.total ) }
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

BillingTable.propsTypes = {
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.object.isRequired
}

export default BillingTable;

// #endregion


// #region JSX

///
const renderTableHeadings = columns => {
  return (
    <thead>
      <tr>
        { columns.map( ( column, index ) =>
          <th className="th_" key={ index } style={{ width: column.width, textAlign: column.alignment }}>{ column.name }</th> ) }
      </tr>
    </thead>
  );
}

///
const renderBillingTable = ( items, total ) => {
  return (
    <div>
      { items.length === 0 &&
        <table className="billing-table">
          { renderTableHeadings( Columns.billingX ) }
        </table>
      }
      { items.map( item =>
        <div key={ item.category }>
          <table className="billing-table">
            { renderTableHeadings( Columns.billingX ) }
            <tbody>
              <tr>
                <td></td>
                <td><u>{ item.name }</u></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              { item.items.map( ( lineItem, index ) =>
                <tr className="line-item" key={ index }>
                  <td></td>
                  <td className="line-item-td" onClick={ () => billingLineItemOnClick( lineItem.roleName ) }></td>
                  <td className="line-item-td" onClick={ () => billingLineItemOnClick( lineItem.roleName ) }>{ lineItem.roleName }</td>
                  <td className="line-item-td" onClick={ () => billingLineItemOnClick( lineItem.roleName ) } style={{ textAlign: Columns.billingX[5].alignment }}>{ lineItem.callCount }</td>
                  <td className="line-item-td" onClick={ () => billingLineItemOnClick( lineItem.roleName ) } style={{ textAlign: Columns.billingX[5].alignment }}>.{ String.prototype.padStart( 2, lineItem.price ) }</td>
                  <td                          onClick={ () => billingLineItemOnClick( lineItem.roleName ) } style={{ textAlign: Columns.billingX[5].alignment }}></td>
                </tr>
              ) }
              {// [<row_name>, <row_calls>, <row_cost>, <arrow>]
                [
                  [Columns.billingY[0].name, item.summary.totalCalls, '', ''],
                  [Columns.billingY[1].name, item.freeLimit !== 0 ? item.freeLimit : 'Unlimited', '', ''],
                  [Columns.billingY[2].name, item.summary.overageCalls, '$' + ( item.summary.overageCost / 100 ).toFixed( 2 ), '\u279C']
                ].map( row =>
                  <tr key={ row[0] }>
                    <td><b>{ row[0] }</b></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: Columns.billingX[3].alignment }}><b>{ row[1] }</b></td>
                    <td style={{ textAlign: Columns.billingX[4].alignment }}>{ row[3] }</td>
                    <td style={{ textAlign: Columns.billingX[5].alignment }}><b>{ row[2] }</b></td>
                  </tr>
                ) }
            </tbody>
          </table>
          <br /><br />
        </div>
      ) }
      <table id="totals-table">
        <tbody>
          {/* [<row_name>, <row_calls>, <row_cost>, <class_name>, <arrow>] */}
          { [
              ['Total Calls', total.calls, '', '', ''],
              ['Total Overage', total.overage, total.cost, 'total-cost', '\u279C']
            ].map( row =>
            <tr key={ row[0] }>
              <td style={{ width: Columns.billingX[0].width }}><b>{ row[0] }</b></td>
              <td style={{ width: Columns.billingX[1].width }}></td>
              <td style={{ width: Columns.billingX[2].width }}></td>
              <td style={{ width: Columns.billingX[3].width, textAlign: Columns.billingX[3].alignment }}><b>{ row[1] }</b></td>
              <td style={{ width: Columns.billingX[4].width, textAlign: Columns.billingX[4].alignment }}>{ row[4] }</td>
              <td style={{ width: Columns.billingX[5].width, textAlign: Columns.billingX[5].alignment }}><div className={ row[3] }><b>{ row[2] !== '' ? '$' + ( row[2] / 100 ).toFixed( 2 ) : '' }</b></div></td>
            </tr>
          ) }
        </tbody>
      </table>
      <br />
    </div>
  );
}

// #endregion


// #region Logic

const billingLineItemOnClick = endpoint => {
  Utilities.setSessionStorageItem( 'endpoint', endpoint );
  Utilities.changeWindowLocation( '/activity/details' );
}

// #endregion