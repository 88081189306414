// Domain changes depending on build type
const productionDomains = {
  apiDomain:       'https://data.pollstar.com',
  siteDomain:      'https://developers.pollstar.com'
};

const developmentDomains = {
  apiDomain:       'https://localhost:44315',
  siteDomain:      'http://developers.pollstar.local'
};

export const UrlDomains = process.env.NODE_ENV === 'development' ? developmentDomains : productionDomains;



// Column info for each apiLogTable view
const columns = {
  summary: [
    { name: 'Endpoint',     width: '220px' },
    { name: 'Total Calls',  width: '120px' },
    { name: 'Avg Duration', width: '120px' },
    { name: 'Latest Date',  width: '120px' },
    { name: 'Latest Time',  width: '160px' }
  ],
  details: [
    { name: 'Path',     width: '350px' },
    { name: 'Duration', width: '120px' },
    { name: 'Date',     width: '120px' },
    { name: 'Time',     width: '160px' }
  ],
  history: [
    { name: 'Endpoint', width: '220px' },
    { name: 'Path',     width: '350px' },
    { name: 'Duration', width: '120px' },
    { name: 'Date',     width: '120px' },
    { name: 'Time',     width: '160px' }
  ],
  billingX: [
    { name: '',         width: '160px', alignment: 'left' },
    { name: 'Group',    width: '120px', alignment: 'left' },
    { name: 'Endpoint', width: '220px', alignment: 'left' },
    { name: 'Calls',    width: '120px', alignment: 'right' },
    { name: 'Rate',     width: '120px', alignment: 'right' },
    { name: 'Cost',     width: '120px', alignment: 'right' }
  ],
  billingY: [
    { name: 'Group Total',  width: '160px' },
    { name: 'Limit',        width: '160px' },
    { name: 'Overage',      width: '160px' }
  ],
  admin: [
    { name: 'Name',     width: '160px', alignment: 'left' },
    { name: 'Email',    width: '160px', alignment: 'left' },
    { name: 'Id',       width: '160px', alignment: 'right'},
    { name: 'Current',  width: '160px', alignment: 'right' },
    { name: '30 Days',  width: '160px', alignment: 'right' },
    { name: 'Total',    width: '160px', alignment: 'right' }
  ]
};

export const Columns = columns;



// Months for the MonthYearDatePicker
const months = [
  { name: 'January',    id: 0 },
  { name: 'February',   id: 1 },
  { name: 'March',      id: 2 },
  { name: 'April',      id: 3 },
  { name: 'May',        id: 4 },
  { name: 'June',       id: 5 },
  { name: 'July',       id: 6 },
  { name: 'August',     id: 7 },
  { name: 'September',  id: 8 },
  { name: 'October',    id: 9 },
  { name: 'November',   id: 10 },
  { name: 'December',   id: 11 }
];

export const Months = months;



// Error Messages
const errorMessages = {
  generic: 'An error has occurred. Please refresh the page.'
}

export const ErrorMessages = errorMessages;