import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Card } from 'reactstrap';
import moment from 'moment';

import ActivityHeader     from './activity-header/ActivityHeader';
import Summary            from './summary/Summary';
import History            from './history/History';
import Details            from './details/Details';
import Utilities          from '../../helpers/Utilities';
import { UrlDomains }     from '../../helpers/Config';
import './Activity.scss';
import '../layout/card-link/CardLink.scss';

class Activity extends React.Component {
  // #region Constructor

  state = {
    activeActivityTab: '',
    plotBy: 'Month',
    startDate: moment().subtract( 27, 'days' ).year() + '-'
      + ( moment().subtract( 27, 'days' ).month() + 1 ) + '-'
      + moment().subtract( 27, 'days' ).date(),
    endDate: moment().year() + '-'
      + ( moment().month() + 1 ) + '-'
      + moment().date()
  }

  // #endregion


  // #region Lifecycle

  ///
  render = () => {
    return(
      <div id="activity">
        <Router>
          <ActivityHeader
            activeActivityTab={ this.state.activeActivityTab }
            buttonRef={ this.summaryButtonRef }
            setSearchParams={ this.setSearchParams }
            startDate={ this.state.startDate }
            endDate={ this.state.endDate }
            plotBy={ this.state.plotBy }
          />
          <br />
          <Route
            path="/activity/summary"
            render={ () =>
              <Summary
                startDate={ this.state.startDate }
                endDate={ this.state.endDate }
                plotBy={ this.state.plotBy }
                isLoggedIn={ this.props.isLoggedIn }
                setActiveMainTab={ this.props.setActiveMainTab }
                setActiveActivityTab={ this.setActiveActivityTab }
              />
            }
          />
          <Route
            path="/activity/details"
            render={ () =>
              <Details
                startDate={ this.state.startDate }
                endDate={ this.state.endDate }
                plotBy={ this.state.plotBy }
                isLoggedIn={ this.props.isLoggedIn }
                setActiveMainTab={ this.props.setActiveMainTab }
                setActiveActivityTab={ this.setActiveActivityTab }
              />
            }
          />
          <Route
            path="/activity/history"
            render={ () =>
              <History
                startDate={ this.state.startDate }
                endDate={ this.state.endDate }
                plotBy={ this.state.plotBy }
                isLoggedIn={ this.props.isLoggedIn }
                setActiveMainTab={ this.props.setActiveMainTab }
                setActiveActivityTab={ this.setActiveActivityTab }
              />
            }
          />

          { window.location.href === ( UrlDomains.siteDomain + '/activity' ) &&
            <div>
              <br />
              <div className="card-container">
                <Card onClick={ () => Utilities.changeWindowLocation( '/activity/summary' ) } title="https://developers.pollstar.com/activity/summary">
                  <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24"><path fill="#FFFFFF" d="M3,4H7V8H3V4M9,5V7H21V5H9M3,10H7V14H3V10M9,11V13H21V11H9M3,16H7V20H3V16M9,17V19H21V17H9" /></svg>
                  <div className="card-title-container">Summary</div>
                </Card>
                
                <Card onClick={ () => Utilities.changeWindowLocation( '/activity/details' ) } title="https://developers.pollstar.com/activity/details">
                  <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24"><path fill="#FFFFFF" d="M2 14H8V20H2M16 8H10V10H16M2 10H8V4H2M10 4V6H22V4M10 20H16V18H10M10 16H22V14H10" /></svg>
                  <div className="card-title-container">Details</div>
                </Card>
                    
                <Card onClick={ () => Utilities.changeWindowLocation( '/activity/history' ) } title="https://developers.pollstar.com/activity/history">
                  <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24"><path fill="#FFFFFF" d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" /></svg>
                  <div className="card-title-container">History</div>
                </Card>
              </div>
              <br /><br />
            </div>
          }

        </Router>
      </div>
    )
  }

  ///
  componentDidMount = () => {
    window.addEventListener( 'beforeunload', this.onUnload );
    this.initHeaderValues();
  }

  ///
  componentWillUnmount = () => {
    window.removeEventListener( 'beforeunload', this.onUnload );
  }

  // #endregion


  // #region User-Generated Events

  ///
  onUnload = event => {
    event.preventDefault();
    Utilities.setSessionStorageItem( 'startDate', this.state.startDate );
    Utilities.setSessionStorageItem( 'endDate', this.state.endDate );
    Utilities.setSessionStorageItem( 'plotBy', this.state.plotBy );
  }

  ///
  plotByOnClick = event => {
    Utilities.setSessionStorageItem( 'plotBy', event.currentTarget.innerHTML );
    this.setState({ plotBy: event.currentTarget.innerHTML });
  }

  // #endregion


  // #region Helpers

  ///
  setActiveActivityTab = tabName => {
    this.setState({ activeActivityTab: tabName });
  }
  
  ///
  initHeaderValues = () => {
    this.setSearchParams(
      !sessionStorage || !sessionStorage.getItem( 'startDate' ) ? this.state.startDate : sessionStorage.getItem( 'startDate' ),
      !sessionStorage || !sessionStorage.getItem( 'endDate' )   ? this.state.endDate   : sessionStorage.getItem( 'endDate' ),
      !sessionStorage || !sessionStorage.getItem( 'plotBy' )    ? this.state.plotBy    : sessionStorage.getItem( 'plotBy' )
    );
  }

  ///
  setSearchParams = ( startDate, endDate, plotBy ) => {
    Utilities.setSessionStorageItem( 'startDate', startDate );
    Utilities.setSessionStorageItem( 'endDate', endDate );
    Utilities.setSessionStorageItem( 'plotBy', plotBy );
    this.setState({ startDate, endDate, plotBy });
  }

  // #endregion
}

Activity.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setActiveMainTab: PropTypes.func.isRequired
}

export default Activity;