import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './LoginForm.scss';

const LoginForm = props => {
  return(
    <div className="form-content">
      <div className="header">
        <h5>Login</h5>
      </div>
      <br />
      <div className="body">
        { props.pinRequired && 
          <div className="pin-required">Please enter the 4-digit pin number sent to your email</div>
        }
        <form>
          <input
            id="email-input"
            ref={ props.emailInputRef }
            type="email"
            placeholder="Email:"
            autoComplete="username"
            autoFocus
            onKeyPress={ event => onKeyPressFormInput(
              props.submitLogin,
              event,
              props.redirectPath,
              props.emailInputRef,
              props.passwordInputRef )
            }
          />
          <br /><br />
          <input
            id="password-input"
            ref={ props.passwordInputRef }
            type="password"
            placeholder={ props.pinRequired ? 'Pin:' : 'Password:'}
            autoComplete={ props.pinRequired ? 'one-time-password' : 'current-password'}
            onKeyPress={ event => onKeyPressFormInput(
              props.submitLogin,
              event,
              props.redirectPath,
              props.emailInputRef,
              props.passwordInputRef )
            }
          />
        </form>
      </div>
      <br />
      <div className="footer">
        <Button
          onClick={ () => props.submitLogin(
            props.redirectPath,
            props.emailInputRef,
            props.passwordInputRef,
            props.setPinRequired )
          }
          >Submit
        </Button>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  emailInputRef:      PropTypes.object.isRequired,
  passwordInputRef:   PropTypes.object.isRequired,
  submitLogin:        PropTypes.func.isRequired,
  redirectPath:       PropTypes.string.isRequired,
  pinRequired:        PropTypes.bool.isRequired
}

export default LoginForm;

// #region User-Generated Events

const onKeyPressFormInput = ( submitLogin, event, redirectPath, emailInputRef, passwordInputRef, setPinRequired ) => {
  if( event.key === 'Enter' )
    submitLogin( redirectPath, emailInputRef, passwordInputRef, setPinRequired );
}

// #endregion