import React from 'react';
import PropTypes from 'prop-types';

import './ApiLogList.scss';

import Utilities from '../../../helpers/Utilities';
import { UrlDomains, Columns } from '../../../helpers/Config';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

// #region Wrapper

///
const ApiLogList = props => {
  if( props.pageName === 'summary' )
    return renderSummaryList( props );
  else if( props.pageName === 'details' )
    return renderDetailsList( props );
  else if( props.pageName === 'history' )
    return renderHistoryList( props );
  else {
    console.log( 'invalid pageName prop to ApiLogList' );
    return <div className="api-log-List"></div>;
  }
}

ApiLogList.propsTypes = {
  isLoading: PropTypes.bool.isRequired,
  pageName: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default ApiLogList;

// #endregion


// #region JSX

///
const renderSummaryList = props => {
  return (
    <div id="summary-list-container">
      <ul className="summary-list">{ props.data.map( summary =>
        <li key={ summary.endpoint }>
          <div onClick={ () => onClickListItem( summary.endpoint ) } className="summary-container">
            <h4><u>{ summary.endpoint }</u></h4>
            <div><b>{ Columns.summary[1].name }</b>: { summary.totalCalls }</div>
            <div><b>{ Columns.summary[2].name }</b>: { summary.averageTime ? summary.averageTime + 'ms' : 'n/a' }</div>
            <div><b>{ Columns.summary[3].name }</b>: { Utilities.parseDate( summary.mostRecent ) }</div>
            <div><b>{ Columns.summary[4].name }</b>: { Utilities.parseAndFormatTime( summary.mostRecent ) }</div>
          </div>
          <br />
        </li> ) }
      </ul>
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

///
const renderDetailsList = props => {
  return (
    <div id="details-list-container">
      <ul className="details-list">{ props.data.map( log => 
        <li key={ log.id }>
          <div className="details-container">
            <div><b>{ Columns.details[0].name }</b>: { log.pathAndQuery }</div>
            <div><b>{ Columns.details[1].name }</b>: { log.elapsedTime ? log.elapsedTime + 'ms' : 'n/a' }</div>
            <div><b>{ Columns.details[2].name }</b>: { Utilities.parseDate( log.callTime ) }</div>
            <div><b>{ Columns.details[3].name }</b>: { Utilities.parseAndFormatTime( log.callTime ) }</div>
          </div>
          <br />
        </li> ) }
      </ul>
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

///
const renderHistoryList = props => {
  return (
    <div id="history-list-container">
      <ul className="history-list">{ props.data.map( log =>   
        <li key={ log.id }>
          <div onClick={ () => onClickListItem( log.endpoint ) } className="history-container">
            <h4>{ log.endpoint }</h4>
            <div><b>{ Columns.history[1].name }</b>: { log.pathAndQuery }</div>
            <div><b>{ Columns.history[2].name }</b>: { log.elapsedTime ? log.elapsedTime + 'ms' : 'n/a' }</div>
            <div><b>{ Columns.history[3].name }</b>: { Utilities.parseDate( log.callTime ) }</div>
            <div><b>{ Columns.history[4].name }</b>: { Utilities.parseAndFormatTime( log.callTime ) }</div>
          </div>
          <br />
        </li> ) }
      </ul>
      <LoadingSpinner isLoading={props.isLoading} dimBackground={true}></LoadingSpinner>
    </div>
  );
}

// #endregion


// #region Logic

///
const onClickListItem = endpoint => {
  Utilities.setSessionStorageItem( 'endpoint', endpoint );
  window.location = UrlDomains.siteDomain + '/activity/details/';
}

// #endregion